import Requester from './requester';
import isEmpty from 'lodash/isEmpty';
import { isPersonalPortal } from 'helpers';
import { selectAccessToken } from 'store/selectors';
import { store } from 'configs/store';

const queryString = require('query-string');

export class API extends Requester {
  constructor() {
    super(process.env.REACT_APP_API_ENDPOINT, store);
    this.portal = isPersonalPortal() ? 'users' : 'business';
  }

  /**
   * @protected
   */
  getBearerToken() {
    return selectAccessToken(this.store.getState());
  }

  async doLogin(data) {
    return this.request({
      url: '/v2/public/sign-in',
      method: 'POST',
      data
    });
  }

  async doVerifyLoginOTP(data) {
    return this.request({
      url: '/v2/public/verify',
      method: 'POST',
      data
    });
  }

  async doVerifyLogin2Factor(data) {
    return this.request({
      url: '/v2/public/verify',
      method: 'POST',
      data
    });
  }

  async sendEmailForgotPassword(data) {
    return this.request({
      url: '/v2/public/recovery',
      method: 'POST',
      data
    });
  }

  async doVerifyOTPForgotPassword(data) {
    return this.request({
      url: '/v2/public/recovery',
      method: 'POST',
      data
    });
  }

  async resetPassword(data) {
    return this.request({
      url: '/v2/public/recovery',
      method: 'POST',
      data
    });
  }

  async doUpdateSubscribeNotification(data) {
    return this.request({
      url: '/v2/public/subscribe-notification',
      method: 'POST',
      data
    });
  }

  async getMyProfile() {
    return this.request({ url: '/v2/users/me', method: 'GET' });
  }

  async getMyBusiness(params) {
    let url = '/v2/users/businesses?';
    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }
    return this.request({ url, method: 'GET' });
  }

  async getMyBusinessToken(data) {
    return this.request({ url: '/v2/users/tokens', method: 'POST', data });
  }

  async doResponseBusinessInvitation(data) {
    return this.request({
      url: `/v2/users/businesses/${data?.businessId}`,
      method: 'POST',
      data: data?.payload
    });
  }

  async updateMyProfile(payload) {
    return this.request({ url: '/v2/users/me', method: 'PUT', data: payload });
  }

  async getCustomers(params, meta) {
    let url = '/v2/business/customers?';
    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({ url, method: 'GET' });
  }

  async createCustomer(data) {
    return this.request({
      url: '/v2/business/customers',
      method: 'POST',
      data
    });
  }

  async getProviders(params, meta) {
    let url = '/v2/business/providers?';
    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }
    return this.request({ url, method: 'GET' });
  }

  async getCategories(params, meta) {
    let url = '/v2/business/case-categories?';
    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }
    return this.request({ url, method: 'GET' });
  }

  async createCategory(data) {
    return this.request({
      url: '/v2/business/case-categories',
      method: 'POST',
      data
    });
  }

  async createCase(data) {
    return this.request({
      url: '/v2/business/cases',
      method: 'POST',
      data
    });
  }

  async getCases(params, meta, portal) {
    let url = `/v2/business/cases?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getCaseDetail(id, meta) {
    let url = `/v2/business/cases/${id}?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getCaseDetailMetadata(id, params) {
    let url = `/v2/business/cases/${id}/metadata?`;

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async updateCaseDetailMetadata(id, payload) {
    let url = `/v2/business/cases/${id}/metadata`;

    return this.request({
      url,
      method: 'POST',
      data: payload
    });
  }

  async getMyMetadata(params) {
    let url = '/v2/members/me/metadata?';

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async updateMyMetadata(payload) {
    return this.request({
      url: '/v2/members/me/metadata',
      method: 'POST',
      data: payload
    });
  }

  async updateCaseDetail(caseId, payload) {
    return this.request({
      url: `/v2/business/cases/${caseId}`,
      method: 'PUT',
      data: payload
    });
  }

  async getBusinesses(params, meta) {
    let url = '/v2/business/contact-business?';

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getRequests(params, meta) {
    let url = '/v2/business/requests?';

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getRequestStatuses() {
    const url = '/v2/business/request-statuses';

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getRequestTypes() {
    const url = '/v2/business/request-types';

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getRequestResolutions() {
    const url = '/v2/business/request-resolutions';

    return this.request({
      url,
      method: 'GET'
    });
  }

  async createBusiness(data) {
    return this.request({
      url: '/v2/business/contact-business',
      method: 'POST',
      data
    });
  }

  async getBusinessTypes() {
    const url = '/v2/business/business-types';

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getCustomerDetail(id, meta) {
    let url = `/v2/business/customers/${id}?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async updateCustomerDetail(customerId, payload) {
    return this.request({
      url: `/v2/business/customers/${customerId}`,
      method: 'PUT',
      data: payload
    });
  }

  async getBusinessDetail(id, meta) {
    let url = `/v2/business/contact-business/${id}?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async updateBusinessDetail(businessId, payload) {
    return this.request({
      url: `/v2/business/contact-business/${businessId}`,
      method: 'PUT',
      data: payload
    });
  }

  async getRequestDetail(id, meta) {
    let url = `/v2/business/requests/${id}?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async updateRequestDetail(requestId, payload) {
    return this.request({
      url: `/v2/business/requests/${requestId}`,
      method: 'PUT',
      data: payload
    });
  }

  async createRequest(payload) {
    return this.request({
      url: '/v2/business/requests',
      method: 'POST',
      data: payload
    });
  }

  async getLetterContent(id, meta = {}) {
    let url = `v2/business/letters/${id}/content?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    return this.request({
      url,
      method: 'GET',
      responseType: 'blob'
    });
  }

  async getRequestTemplates() {
    return this.request({
      url: '/v2/business/templates',
      method: 'GET'
    });
  }

  async getPreviewLetter(data) {
    return this.request({
      url: '/v2/business/helpers',
      method: 'POST',
      data
    });
  }

  async upLoadFiles(data, portal) {
    return this.request({
      url: `/v2/${portal ?? this.portal}/files`,
      method: 'POST',
      data
    });
  }

  async getMembers(params, meta) {
    let url = `/v2/${this.portal}/contact-members?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getBusinessCategories(params, meta) {
    let url = '/v2/business/categories?';

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async createMember(data) {
    return this.request({
      url: '/v2/business/contact-members',
      method: 'POST',
      data
    });
  }

  async downloadFile(fileId) {
    return this.request({
      url: `/v2/business/files/${fileId}`,
      method: 'GET'
    });
  }

  async replyRequest(requestId, payload) {
    const portal = isPersonalPortal() ? 'users' : 'business';
    return this.request({
      url: `/v2/${portal}/requests/${requestId}/letter`,
      method: 'POST',
      data: payload
    });
  }
  async replySharedRequest(requestId, payload) {
    return this.request({
      url: `/v2/users/requests/${requestId}/letter`,
      method: 'POST',
      data: payload
    });
  }

  async shareParticipants(requestId, payload, portal) {
    return this.request({
      url: `/v2/${portal ?? this.portal}/requests/${requestId}/participants`,
      method: 'PUT',
      data: payload
    });
  }

  async updatePermShareParticipants(requestId, payload) {
    /*
      Sample payload:
      {
          action: "view"
          participantId: 123456
      }
    */
    return this.request({
      url: `/v2/${this.portal}/requests/${requestId}/participants`,
      method: 'PUT',
      data: payload
    });
  }
  async deletePermShareParticipants(requestId, payload) {
    /*
      Sample payload:
      {
          action: "view"
          participantId: 123456
      }
    */
    return this.request({
      url: `/v2/${this.portal}/requests/${requestId}/participants`,
      method: 'PUT',
      data: payload
    });
  }

  async getRequestHashTags() {
    return this.request({
      url: `/v2/${this.portal}/request-hashtags`,
      method: 'GET'
    });
  }

  async updateRequestHashTags(hashtags = [], requestId = '') {
    return this.request({
      url: `/v2/business/request-hashtags/${requestId}`,
      method: 'PUT',
      data: { hashtags }
    });
  }

  async createRequestHashTag(hashtag = '') {
    return this.request({
      url: `/v2/business/request-hashtags`,
      method: 'POST',
      data: { label: hashtag, value: hashtag }
    });
  }

  async conversation(requestId, payload, portal) {
    /*
      This is use for create or send message to conversation
      Sample payload:
      ** CREATE **
      {
          "letterId": "{letter_id}",
          "title": "hello",
          "participants": [
              {
                  "emailAddress": "test@sterlingpharmacyrx.com"
              }
          ]
      }
      ** SEND MESSAGE **
      {
          "conversationId": "{conversation_id}",
          "content": "hello"
      }
    */
    return this.request({
      url: `/v2/${portal ?? this.portal}/requests/${requestId}/conversation`,
      method: 'POST',
      data: payload
    });
  }

  async updateConversation(requestId, payload) {
    /*
     ** UPDATE **
      {
          "conversationId": "{conversation_id}",
          "title": "hello",
          "participants": [
              {
                  "emailAddress": "test@sterlingpharmacyrx.com"
              }
          ]
      }
    */
    return this.request({
      url: `/v2/${this.portal}/requests/${requestId}/conversation`,
      method: 'PUT',
      data: payload
    });
  }

  async updateCaseAvatar(caseId = '', formData = {}) {
    return this.request({
      url: `/v2/business/cases/${caseId}`,
      method: 'PUT',
      data: formData
    });
  }
  async getDefaultCaseThumbnails() {
    return this.request({
      url: '/v2/public/settings',
      method: 'GET'
    });
  }
  async getSettings() {
    return this.request({
      url: '/v2/public/settings',
      method: 'GET'
    });
  }
  async responseRequestDeal(requestId, payload) {
    return this.request({
      url: `/v2/${this.portal}/requests/${requestId}`,
      method: 'PUT',
      data: payload
    });
  }
  async responseSharedRequestDeal(requestId, payload) {
    return this.request({
      url: `/v2/users/requests/${requestId}`,
      method: 'PUT',
      data: payload
    });
  }
  async getMemberDetail(memberId, payload) {
    return this.request({
      url: `/v2/${this.portal}/contact-members/${memberId}`,
      method: 'GET',
      data: payload
    });
  }

  async updateMemberDetail(memberId, payload) {
    return this.request({
      url: `/v2/${this.portal}/contact-members/${memberId}`,
      method: 'PUT',
      data: payload
    });
  }

  async getConversations(data) {
    return this.request({
      url: `/v2/${data?.portal ?? this.portal}/requests/${data?.requestId}/conversation`,
      method: 'GET',
      data: data?.payload
    });
  }


  async getSharedRequests(params, meta) {
    let url = '/v2/users/requests?';

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getUserMetadata(params) {
    let url = '/v2/users/me/metadata?';

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async updateUserMetadata(payload) {
    return this.request({
      url: '/v2/users/me/metadata',
      method: 'POST',
      data: payload
    });
  }

  async getSharedCases(params, meta, portal) {
    let url = `/v2/business/shared-cases?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    if (params && !isEmpty(params)) {
      url = `${url}${queryString.stringify(params)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async getSharedCaseDetail(id, meta) {
    let url = `/v2/business/shared-cases/${id}?`;

    if (meta && !isEmpty(meta)) {
      url = `${url}meta=${JSON.stringify(meta)}&`;
    }

    return this.request({
      url,
      method: 'GET'
    });
  }

  async updateSharedCaseDetail(caseId, payload) {
    return this.request({
      url: `/v2/business/shared-cases/${caseId}`,
      method: 'PUT',
      data: payload
    });
  }

}

export default new API();
