export * from './case';
export * from './customer';
export * from './business';
export * from './request';
export * from './api';

export const stateList = [
  // For case no selected state
  { value: '', label: '' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
  { value: 'AA', label: 'Armed Forces (AA)' },
  { value: 'AE', label: 'Armed Forces (AE)' },
  { value: 'AP', label: 'Armed Forces (AP)' }
];

export const MODAL_NAMES = {
  ACTIVE_USER_FORM: 'active-user-form',
  CREATE_CLIENT: 'create-client',
  UPDATE_CASE_CLIENT: 'update-case-client',
  CREATE_CATEGORY: 'create-category',
  SEARCH_CASES_FORM: 'search-cases-form',
  SEARCH_CUSTOMERS_FORM: 'search-customers-form',
  CASE_DETAIL_FORM: 'case-detail-form',
  IMAGE_SLIDER: 'image-slider',
  REMEMBER_ME: 'remember-me',
  CUSTOMIZE_COLUMNS: 'customize-columns',
  SEARCH_BUSINESSES_FORM: 'search-businesses-form',
  SEARCH_REQUESTS_FORM: 'search-requests-form',
  CREATE_BUSINESSS: 'create-business',
  SEARCH_CASE_DETAIL_FORM: 'search-case-detail-form',
  CREATE_BUSINESS: 'create-business',
  EDIT_TIMELINE: 'edit-timeline',
  SEARCH_CUSTOMER_DETAIL_FORM: 'search-customer-detail-form',
  EDIT_CLIENT: 'edit-client',
  SELECT_CASE_MODAL: 'select-case-modal',
  SHARE_OTHER: 'share-other-form',
  SEARCH_MEMBERS_FORM: 'search-members-form',
  CREATE_MEMBER: 'create-member',
  FILE_SLIDER: 'file-slider',
  FULL_VIEW_LETTER: 'full-view-letter',
  REQUEST_REPLY_FORM: 'request-reply-form',
  MANAGE_CHECKLIST: 'manage-checklist',
  UPDATE_CASE_AVATAR: 'update-case-avatar',
  UPDATE_PROFILE_AVATAR: 'update-profile-avatar',
  SHARE_TOPIC_FORM: 'share-topic-form',
  CREATE_CONVERSATION: 'create-conversation',
  RETAIN_CASE: 'retain-case'
};

export const API_RESPONSE_CODE = {
  BAD_REQUEST: 400,
  SUCCESS: 200,
  ACCEPTED: 202,
  CREATED: 201,
  NO_CONTENT: 204,
  UNAUTHORIZE: 401,
  PERMISSION_DENY: 403,
  NOT_FOUND: 404,
  SERVER_INTERRUPT: 500
};

export const META_PUSH_PIN_ICON = `<path fill-rule="evenodd" d="M16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3z"></path>`;
export const META_PUSH_PIN_OUT_LINE_ICON = `<path fill-rule="evenodd" d="M14 4v5c0 1.12.37 2.16 1 3H9c.65-.86 1-1.9 1-3V4h4m3-2H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3V4h1c.55 0 1-.45 1-1s-.45-1-1-1z"></path>`;

export const REGEX_NUMBER_ONLY = /^\d+$/;
export const PDF_TYPE = 'application/pdf';

export const DEFAULT_PIN_IMAGE =
  'https://static.portalassistant.com/be9d6ef9-c4cc-4e60-965d-04a8e63eb771.jpg';

export const ACCEPT_FILE = [
  'image/*',
  '.xlsx',
  '.xls',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.txt',
  '.pdf'
];

export const LIMITED_FILES = 5;
export const MAX_FILE_SIZE = 1024 * 1024 * 5; // 5MB

export const EDITOR_EMPTY = '<p><br></p>';
export const STATIC_URL = process.env.REACT_APP_STATIC_ENDPOINT;
export const IS_HIDE_MODULE = 'TRUE';
