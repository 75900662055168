import get from 'lodash/get';

export const selectLoadingFullscreen = (state) =>
  get(state, 'app.isLoadingFullscreen', false);
export const selectModal = (state) => get(state, 'app.modal', {});
export const selectUserMetadata = (state) => get(state, 'app.metadata', {});
export const selectSettings = state => get(state, 'app.settings', {});
export const selectSubmitRequestTypes = state => get(state, 'app.settings.submitRequestTypes', {});
export const selectSubmitRequestTypeOpts = state => get(state, 'app.settings.submitRequestTypeOpts', []);
export const selectDefaultSubmitRequestTypeOpt = state => get(state, 'app.settings.submitRequestTypeOpts', []).find(o => o?.isDefault);
