import API from 'api';
import businessTypes from '../types/business';
import isEmpty from 'lodash/isEmpty';

export const getBusinesses = (
  params = {},
  meta = {},
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_BUSINESSES,
    payload: { isLoadmore, isAll: isEmpty(params) },
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getBusinesses(params, meta);
      return res;
    }
  };
};

export const resetPinBusinesses = () => {
  return {
    type: businessTypes.RESET_PIN_BUSINESSES,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const getPinBusinesses = (
  params = {},
  meta = {},
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_PIN_BUSINESSES,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getBusinesses(params, meta);
      return res;
    }
  };
};

export const createBusiness = (payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.CREATE_BUSINESS,
    payload,
    asyncCall: async () => {
      const res = await API.createBusiness(payload);
      return res;
    }
  };
};

export const updateParamsSearchBusinesses = (payload) => {
  return {
    type: businessTypes.UPDATE_PARAMS_SEARCH_BUSINESSES,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const resetParamsSearchBusinesses = () => {
  return {
    type: businessTypes.RESET_PARAMS_SEARCH_BUSINESSES,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const getBusinessTypes = (afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_BUSINESS_TYPES,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getBusinessTypes();
      return res;
    }
  };
};

export const getBusinessesGlobal = (
  params = {},
  meta = {},
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_BUSINESSES_GLOBAL,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getBusinesses(params, meta);
      return res;
    }
  };
};

export const getBusinessDetail = (
  id,
  meta,
  afterSuccessCall = () => {},
  afterFailureCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_BUSINESS_DETAIL,
    payload: { id },
    asyncCall: async () => {
      const res = await API.getBusinessDetail(id, meta);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const updateBusinessDetail = (
  pk,
  payload,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.UPDATE_BUSINESS_DETAIL,
    payload: {},
    asyncCall: async () => {
      const res = await API.updateBusinessDetail(pk, payload);
      return res;
    },
    afterSuccessCall
  };
};

export const getContactMembers = (
  params,
  meta,
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_CONTACT_MEMBERS,
    payload: { isAll: isEmpty(params), isLoadmore },
    asyncCall: async () => {
      const res = await API.getMembers(params, meta);
      return res;
    },
    afterSuccessCall
  };
};

export const getMembers = (
  params = {},
  meta = {},
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_MEMBERS,
    payload: { isLoadmore },
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getMembers(params, meta);
      return res;
    }
  };
};

export const resetPinMembers = () => {
  return {
    type: businessTypes.RESET_PIN_MEMBERS,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const getPinMembers = (
  params = {},
  meta = {},
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_PIN_MEMBERS,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getMembers(params, meta);
      return res;
    }
  };
};

export const updateParamsSearchMembers = (payload) => {
  return {
    type: businessTypes.UPDATE_PARAMS_SEARCH_MEMBERS,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const resetParamsSearchMembers = () => {
  return {
    type: businessTypes.RESET_PARAMS_SEARCH_BUSINESSES,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const getBusinessCategories = (
  params = {},
  meta = {},
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_BUSINESS_CATEGORIES,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getBusinessCategories(params, meta);
      return res;
    }
  };
};

export const createMember = (payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.CREATE_MEMBER,
    payload,
    asyncCall: async () => {
      const res = await API.createMember(payload);
      return res;
    }
  };
};

export const getMemberDetail = (
  id,
  meta,
  afterSuccessCall = () => {},
  afterFailureCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.GET_MEMBER_DETAIL,
    payload: { id },
    asyncCall: async () => {
      const res = await API.getMemberDetail(id, meta);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const updateMemberDetail = (
  pk,
  payload,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: businessTypes.UPDATE_MEMBER_DETAIL,
    payload: {},
    asyncCall: async () => {
      const res = await API.updateMemberDetail(pk, payload);
      return res;
    },
    afterSuccessCall
  };
};
