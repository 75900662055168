import { asyncTypeSuccess, userTypes } from 'store/types';

import { API_RESPONSE_CODE } from 'const';
import SnackbarUtils from 'portals/business/SnackbarUtils';
import axios from 'axios';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { navigateRef } from 'components/Layout/Wrapper';

class Requester {
  constructor(baseUrl, reduxStore) {
    /**
     * @protected
     */
    this.axios = axios.create({ baseURL: baseUrl });
    /**
     * @protected
     */
    this.store = reduxStore;
  }

  async request(inputConfig) {
    let config = {};

    try {
      const token = localStorage.getItem('token');
      config = merge(config, inputConfig, {
        headers: { authorization: token }
      });

      const response = await this.axios.request(config);

      let responseData = null;

      responseData = get(response, 'data', {});
      switch (responseData.code ?? '') {
        case API_RESPONSE_CODE.UNAUTHORIZED:
          {
            this.store.dispatch({
              type: asyncTypeSuccess(userTypes.USER_LOGOUT)
            });
            localStorage.removeItem('token');
            localStorage.removeItem('bId');
            SnackbarUtils.error("Token expired, please login again.");
            window.location.reload(true);
            break;
          }
        case API_RESPONSE_CODE.PERMISSION_DENY:
          {
            SnackbarUtils.error("Permission denied.");
            navigateRef.current('/403');
            break;
          }
        case API_RESPONSE_CODE.NOT_FOUND:
          {
            SnackbarUtils.error("Not found.");
            navigateRef.current('/404');
            break;
          }
        case API_RESPONSE_CODE.SERVER_INTERRUPT:
          {
            SnackbarUtils.error("Oops, something went wrong. Please try again later.");
            navigateRef.current('/500');
            break;
          }
        default:
          break;
      }
      return responseData;
    } catch (err) {
      SnackbarUtils.error("Oops, something went wrong. Please try again later.");
      const responseStatus = get(err, 'response.status');
      const responseData = get(err, 'response.data', {});

      let message = get(err, 'response.data.message', '');
      if (!message || message === '') {
        message = responseData;
      }
      const errorData = {
        errorStatus: responseStatus,
        message
      };

      throw errorData;
    }
  }
}

export default Requester;
