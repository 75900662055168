import * as yup from 'yup';

function noWhitespace() {
  return this.transform((value, originalValue) => {
    if (originalValue && originalValue.trim() === '') {
      return '';
    }
    return value;
  });
}

yup.addMethod(yup.string, 'noWhitespace', noWhitespace);
