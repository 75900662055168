export const ROUTE_HOMEPAGE = '/';

export const ROUTE_CASE = {
    LIST: '/cases',
    CREATE_CASE: '/create-case',
    CASE_DETAIL: id => `/cases/${id}`
};

export const ROUTE_BUSINESS = {
    SELECT_BUSINESS: '/select-business'
};

export const ROUTE_REQUEST = {
    CREATE_REQUEST: '/create-request',
    REQUEST_DETAIL: id => `/requests/${id}`
};
