import { createRef } from 'react';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const navigateRef = createRef();

const Wrapper = ({ children }) => {
  const navigate = useNavigate();
  navigateRef.current = navigate;
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default Wrapper;
