import { asyncTypeFailure, asyncTypeRequest, asyncTypeSuccess } from '../types';

import get from 'lodash/get';
import userTypes from '../types/user';

const initialState = {
  isAuthLoading: false,
  error: {
    login: null,
    myProfile: null
  },
  successState: {},
  token: '',
  myProfile: {
    isLoading: true,
    data: {},
    isUpdateSuccess: null,
    isLoadingUpdate: false
  }
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.USER_LOGOUT: {
      return {
        ...initialState
      };
    }
    case asyncTypeRequest(userTypes.UPDATE_MY_PROFILE): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          error: null,
          isUpdateSuccess: null,
          isLoadingUpdate: true
        }
      };
    }
    case asyncTypeSuccess(userTypes.UPDATE_MY_PROFILE): {
      return {
        ...state,
        myProfile: {
          data: action.response.data,
          error: null,
          isUpdateSuccess: true,
          isLoadingUpdate: false
        }
      };
    }
    case asyncTypeFailure(userTypes.UPDATE_MY_PROFILE): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isUpdateSuccess: false,
          isLoadingUpdate: false
        }
      };
    }
    case asyncTypeRequest(userTypes.GET_MY_PROFILE): {
      return {
        ...state,
        myProfile: { ...initialState.myProfile, isLoading: true },
        isAuthLoading: true
      };
    }
    case asyncTypeSuccess(userTypes.GET_MY_PROFILE): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          data: action?.response?.data,
          isLoading: false,
          error: null
        },
        isAuthLoading: false
      };
    }
    case asyncTypeFailure(userTypes.GET_MY_PROFILE): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isLoading: false,
          isUpdateSuccess: false
        },
        isAuthLoading: false
      };
    }
    case asyncTypeRequest(userTypes.UPDATE_PASSWORD): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          error: null,
          isUpdateSuccess: null
        }
      };
    }
    case asyncTypeSuccess(userTypes.UPDATE_PASSWORD): {
      return {
        ...state,
        myProfile: {
          data: action.response.data,
          error: null,
          isUpdateSuccess: true
        }
      };
    }
    case asyncTypeFailure(userTypes.UPDATE_PASSWORD): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isLoading: false,
          isUpdateSuccess: false
        }
      };
    }
    case asyncTypeRequest(userTypes.ENABLE_MFA): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          error: null,
          isUpdateSuccess: null
        }
      };
    }
    case asyncTypeSuccess(userTypes.ENABLE_MFA): {
      return {
        ...state,
        myProfile: {
          data: action.response.data,
          error: null,
          isUpdateSuccess: true
        }
      };
    }
    case asyncTypeFailure(userTypes.ENABLE_MFA): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isLoading: false,
          isUpdateSuccess: false
        }
      };
    }

    case userTypes.RESET_ERROR: {
      return {
        ...state,
        error: {},
        myProfile: {
          ...state.myProfile,
          isUpdateSuccess: null
        }
      };
    }
    case asyncTypeFailure(userTypes.USER_LOGIN): {
      return {
        ...state,
        error: {
          login: get(action, 'error.message')
        },
        isAuthLoading: false,
        isLoginSuccess: false,
        successState: {}
      };
    }
    case asyncTypeRequest(userTypes.USER_LOGIN): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        isLoginSuccess: false,
        successState: {}
      };
    }
    case asyncTypeSuccess(userTypes.USER_LOGIN): {
      return {
        ...state,
        error: {},
        isAuthLoading: false,
        isLoginSuccess: true,
        token: get(action, 'response.data.accessToken'),
        successState: {
          isReSendPasscodeSuccess: get(action, 'payload.action') === 'resend'
        }
      };
    }
    case asyncTypeFailure(userTypes.USER_VERIFY_TWO_FACTOR):
    case asyncTypeFailure(userTypes.USER_VERIFY_LOGIN_OTP): {
      return {
        ...state,
        error: {
          login: get(action, 'error.message')
        },
        isAuthLoading: false
      };
    }
    case asyncTypeRequest(userTypes.USER_VERIFY_TWO_FACTOR):
    case asyncTypeRequest(userTypes.USER_VERIFY_LOGIN_OTP): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        successState: {}
      };
    }
    case asyncTypeSuccess(userTypes.USER_VERIFY_TWO_FACTOR):
    case asyncTypeSuccess(userTypes.USER_VERIFY_LOGIN_OTP): {
      return {
        ...state,
        error: {},
        isAuthLoading: false,
        token: get(action, 'response.data.accessToken')
      };
    }

    case asyncTypeFailure(userTypes.USER_SEND_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {
          forgotPassword: get(action, 'error.message')
        },
        isAuthLoading: false,
        successState: {}
      };
    }

    case asyncTypeRequest(userTypes.USER_SEND_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        successState: {}
      };
    }

    case asyncTypeSuccess(userTypes.USER_SEND_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: false,
        successState: {
          isReSendForgotPassSuccess: get(action, 'payload.action') === 'resend'
        }
      };
    }

    case asyncTypeFailure(userTypes.USER_RESET_PASSWORD): {
      return {
        ...state,
        error: {
          forgotPassword: get(action, 'error.message')
        },
        isAuthLoading: false,
        successState: {}
      };
    }

    case asyncTypeRequest(userTypes.USER_RESET_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        successState: {}
      };
    }

    case asyncTypeSuccess(userTypes.USER_RESET_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: false,
        successState: {
          isResetPassSuccess: get(action, 'response.message')
        }
      };
    }
    case asyncTypeFailure(userTypes.USER_VERIFY_OTP_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {
          forgotPassword: get(action, 'error.message')
        },
        isAuthLoading: false,
        successState: {}
      };
    }

    case asyncTypeRequest(userTypes.USER_VERIFY_OTP_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        successState: {}
      };
    }

    case asyncTypeSuccess(userTypes.USER_VERIFY_OTP_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: false
      };
    }

    case asyncTypeRequest(userTypes.RESET_USER): {
      return {
        ...state,
        error: {
          login: null,
          myProfile: null
        },
        successState: {},
        isUpdateSuccess: false,
        isLoginSuccess: false

      };
    }
    case asyncTypeRequest(userTypes.USER_LOGOUT): {
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
};

export default userReducers;
