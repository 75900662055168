import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiPaper-root': {
      // padding: 0,
      // backgroundColor: 'rgb(240, 242, 245)',
    }
  },
  active: {
    backgroundColor: 'rgb(40, 57, 67) !important',
    paddingLeft: '7px !important',
    minWidth: `${theme.spacing(5)} !important`,
    marginLeft: `${theme.spacing(1)} !important`,

    '& .MuiListItemIcon-root': {
      '& .MuiSvgIcon-root': {
        color: 'white !important'
      }
    },
    '& .MuiListItemText-root, & .MuiSvgIcon-root': {
      color: 'white !important'
    }
  },
  sharedRequest: {
    cursor: 'pointer',
    minWidth: 40,
    margin: '6px 14px 6px 8px',
    padding: '6px 10px 6px 6px',
    overflow: 'hidden',
    color: 'rgba(28,43,51,1)',
    fontSize: '14px',
    fontWeight: 400,
    textTransform: 'initial',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: 26,
    },
    '&:hover': {
      background: 'rgba(24, 119, 242, 0.12)',
    },
    '&.active': {
      background: 'rgba(28,43,51,1)',
      color: '#fff',
    }
  },
  popupLogout: {
    '& .MuiPaper-root': {
      minWidth: '268px !important'
    }
  }
}));

export default useStyles;
