import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import ViewListIcon from '@mui/icons-material/ViewList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

// Manage Customers
// Meta Key
export const DEFAULT_PARAM_METADATA_CUSTOMERS = {
  metaKey: `manageCustomersPinIds,manageCustomersColumnHidden,manageCustomersSectionHidden,manageCustomersSectionZoom`
};
export const META_PIN_CUSTOMER = 'manageCustomersPinIds';
export const META_SECTION_CUSTOMER = 'manageCustomersSectionHidden';
export const META_SECTION_CUSTOMER_ZOOM = 'manageCustomersSectionZoom';
export const META_COLUMN_CUSTOMER = 'manageCustomersColumnHidden';
// Section key
export const MANAGE_CUSTOMERS = 'manageCustomers';
export const NEW_CUSTOMER = 'newCustomer';
export const IMPORT_CUSTOMER = 'importCustomer';
export const PIN_CUSTOMERS = 'pinCustomers';
export const MANAGE_CUSTOMER_SECTIONS_VALUE = [
  NEW_CUSTOMER,
  IMPORT_CUSTOMER,
  PIN_CUSTOMERS,
  MANAGE_CUSTOMERS
];
export const MANAGE_CUSTOMER_SECTIONS = [
  {
    label: 'New Client',
    value: 'newCustomer',
    order: 1,
    icon: <PlaylistAddIcon />
  },
  // {
  //   label: 'Import Customer',
  //   value: 'importCustomer',
  //   order: 2,
  //   icon: <VisibilityIcon />
  // },
  {
    label: 'Pinned Clients',
    value: 'pinCustomers',
    order: 3,
    icon: <PushPinIcon />
  },
  {
    label: 'Clients',
    value: 'manageCustomers',
    order: 4,
    icon: <ViewListIcon />
  }
];
// Columns

export const MANAGE_CUSTOMER_COLUMNS = [
  {
    id: 'pin',
    label: 'Pin',
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 1
  },
  {
    id: 'id',
    label: 'Client #',
    align: 'center',
    isDefault: true,
    order: 2
  },
  {
    id: 'clientName',
    label: 'Client Name',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 3
  },
  {
    id: 'dateOfBirth',
    label: 'DoB',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 4
  },
  {
    id: 'gender',
    label: 'Gender',
    isDefault: false,
    order: 5
  },
  { label: 'Email Address', id: 'emailAddress', isDefault: false, order: 6 },
  { label: 'Phone Number', id: 'phoneNumber', isDefault: true, order: 7 },
  {
    label: 'Address',
    id: 'address1',
    isDefault: true,
    order: 8
  },
  {
    label: 'City',
    id: 'city',
    isDefault: true,
    order: 9
  },
  {
    label: 'State',
    id: 'stateCode',
    isDefault: true,
    order: 10
  },
  {
    label: 'Zip/Postal Code',
    id: 'postCode',
    isDefault: true,
    order: 11
  },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 12
  },
  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 13
  },
];

export const DEFAULT_PARAMS_SEARCH_CUSTOMER = {
  s: '',
  firstName: '',
  lastName: '',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: ''
};
