import { AutoCompleteWrapper } from './styled';
import ListBox from './ListBox';
import PropTypes from 'prop-types';
import theme from 'theme';

const Autocomplete = (props) => {
  const {
    isLoadMore = false,
    loadMore = () => {},
    maxHeight = 'auto',
    hasMore = true,
    size = 'small',
    autoComplete = true,
    autoHighlight = true,
    ...rest
  } = props;

  const handleScroll = (event) => {
    if (isLoadMore) {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;
      if (listboxNode.scrollHeight - position <= 1 && hasMore) {
        loadMore();
      }
    }
  };
  return (
    <AutoCompleteWrapper
      {...rest}
      autoComplete={autoComplete}
      autoHighlight={autoHighlight}
      disablePortal
      size={size}
      ListboxComponent={ListBox}
      ListboxProps={{
        onScroll: handleScroll,
        sx: {
          maxHeight,
          '& .MuiAutocomplete-option': rest?.loading
            ? {
                cursor: 'wait'
              }
            : {
                cursor: 'pointer'
              }
        }
      }}
    />
  );
};

Autocomplete.propTypes = {
  size: PropTypes.string
};

export default Autocomplete;
