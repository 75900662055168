import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const InputWrapper = styled(TextField)(({ theme }) => ({
  '& .MuiTextField-root': {
    marginBottom: theme.spacing(2),

    '& .MuiFormHelperText-root': {}
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 4,
    '&.Mui-focused fieldset': {
      border: '1px solid #556cd6'
    }
  },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      border: `2px solid ${theme.palette.fbColor.hoverInput}`
    }
  },
  '& .MuiAutocomplete-endAdornment': {},
  '& .MuiInputBase-sizeSmall': {
    fontSize: 14,
    lineHeight: '20px',
    '& .MuiAutocomplete-endAdornment': {
      lineHeight: '20px'
    }
  },
  '& .MuiInputLabel-sizeSmall': {
    fontSize: 14,
    lineHeight: '20px'
  },
  '&.custom-input-label': {
    '& .MuiFilledInput-root': {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: theme.palette.white.main,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow'
      ]),
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.disabled
      }
    }
  }
}));
