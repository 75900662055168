import { appTypes } from '../types';

export const setLoadingFullscreen = (isLoading = false) => {
  return {
    type: appTypes.SET_LOADING_FULLSCREEN,
    payload: isLoading
  };
};

export const setModalActive = (payload) => {
  /*
    payload: {
      modalName: '',
      data: {} // Data in modal
    }
  */
  return {
    type: appTypes.SET_MODAL_ACTIVE,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const updateModalData = (payload) => {
  /*
    payload: {
      modalName: '',
      data: {} // Data in modal
    }
  */
  return {
    type: appTypes.UPDATE_MODAL_DATA,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};
