import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import reducer from 'configs/reducer';
import asyncActionCreator from 'store/middlewares/asyncActionCreator';

function initStore(persistConfig = {}, rootReducer = null, hotModule = '') {
  const persistedReducer = persistReducer(
    {
      key: 'PT-root',
      storage,
      ...persistConfig
    },
    rootReducer
  );

  const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    persistedReducer,
    enhancer(applyMiddleware(asyncActionCreator, thunk))
  );

  const persistor = persistStore(store);

  if (module.hot && hotModule) {
    module.hot.accept(hotModule, () => store.replaceReducer(require(hotModule)));
  }

  return { store, persistor };
}

const { store, persistor } = initStore({}, reducer, 'configs/reducer');

export { store, persistor, initStore };
