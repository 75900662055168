import { ButtonWrapper, LoadingButtonWrapper } from './styled';

import { Link } from 'react-router-dom';

const Button = ({ linkTo, loading, ...props }) => {
  if (loading) {
    return (
      <LoadingButtonWrapper
        {...props}
        size={props?.size ?? 'large'}
        loading
        loadingPosition="center"
        children={null}
      />
    );
  }
  return (
    <ButtonWrapper
      {...props}
      component={linkTo ? Link : null}
      size={props?.size ?? 'large'}
      to={linkTo ?? null}
    />
  );
};

export default Button;
