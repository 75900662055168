import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#1877f2'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    },
    white: {
      main: '#fff'
    },
    textColor: {
      black: '#1c1e21',
      blackGrey: '#5d5f63',
      grey: '#838589'
    },
    grey: {
      100: '#e4e6eb',
      200: '#EEEEEE',
      300: '#c3c3c3',
      400: '#c1c1c1',
      600: '#757575',
      main: '#f5f5f5',
      darkGrey: '#414141',
      athensGrey: '#F2F3F5',
      light: '#f0f2f5',
      lighten: '#e2e2e1',
      policy: '#575962'
    },
    black: {
      main: '#000',
      pearl: '#18191A',
      balticSea: '#3E4042'
    },
    blue: {
      main: '#1877f2'
    },
    fbColor: {
      main: '#0a78be',
      background: '#e1edf7',
      blur: '#ECF3FF',
      black: '#050505',
      backgroundBtn: '#E4E6EB',
      backgroundBtnHover: '#d2d4d9',
      hoverInput: '#cbd2d9',
      borderGrey: '#65676B',
      backgroundBtnGrey: '#283943',
      borderGrey100: '#ced0d4',
      subTitleGrey: '#65676b'
    },
    success: {
      main: '#28a745'
    },
    disabled: '#f5f5f5',
    background: {
      lightPink: '#ffeef0'
    }
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 900,
  //     lg: 1200,
  //     xl: 1440,
  //   },
  // },
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: [
      'SF Pro Display',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h2: {
      fontSize: 20,
      color: '#162643'
    },
    h5: {
      fontSize: 20,
      color: '#1c1e21'
    },
    h6: {
      fontSize: 15,
      color: '#1c1e21'
    },
    button: {
      fontSize: 20,
      backgroundColor: '#1877f2'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#c1c1c1 #f1f1f1',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#f1f1f1',
            width: '12px',
            height: '12px',
            borderRadius: 8
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#c1c1c1',
            border: '3px solid #f1f1f1'
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: '#adadad'
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: '#adadad'
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: '#adadad'
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#f1f1f1'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 5px 0 rgb(0 0 0 / 5%), 0 0 1px 0 rgb(0 0 0 / 5%)'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 5px 0 rgb(0 0 0 / 5%), 0 0 1px 0 rgb(0 0 0 / 25%)'
        }
      }
    }
  },

  loprxStyles: {
    lightShadow: '0 0 5px 0 rgb(0 0 0 / 5%), 0 0 1px 0 rgb(0 0 0 / 5%)',
    lightShadow15: '0 0 5px 0 rgb(0 0 0 / 15%), 0 0 1px 0 rgb(0 0 0 / 15%)',
    lightShadowBottom:
      '0 5px 5px 0 rgb(0 0 0 / 5%), 0 1px 1px 0 rgb(0 0 0 / 5%)'
  }
});

export default theme;
