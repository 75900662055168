import { asyncTypeFailure, asyncTypeRequest, asyncTypeSuccess } from '../types';

import { DEFAULT_PARAMS_SEARCH_CUSTOMER } from 'const';
import customerTypes from '../types/customer';

const initialState = {
  customer: {
    isAll: false,
    list: [],
    isLoadingList: false,
    created: null,
    isLoadingCreate: false,
    listPin: [],
    isLoadingPin: false,
    customer: {},
    isLoadingCustomer: true
  },
  paramsSearchCustomers: DEFAULT_PARAMS_SEARCH_CUSTOMER
};

const customerReducers = (state = initialState, action) => {
  switch (action.type) {
    case asyncTypeRequest(customerTypes.GET_CUSTOMERS): {
      return {
        ...state,
        customer: {
          ...state.customer,
          isAll: action?.payload?.isAll,
          isLoadingList: true
        }
      };
    }
    case asyncTypeSuccess(customerTypes.GET_CUSTOMERS): {
      const { isLoadmore } = action?.payload;

      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingList: false,
          list: isLoadmore
            ? [...state.customer.list, ...action?.response?.data]
            : action?.response?.data
        }
      };
    }
    case asyncTypeFailure(customerTypes.GET_CUSTOMERS): {
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingList: false,
          list: []
        }
      };
    }
    // Create customer
    case asyncTypeRequest(customerTypes.CREATE_CUSTOMER): {
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingCreate: true,
          created: null
        }
      };
    }
    case asyncTypeSuccess(customerTypes.CREATE_CUSTOMER): {
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingCreate: false,
          created: action?.response?.data || {}
        }
      };
    }
    case asyncTypeFailure(customerTypes.CREATE_CUSTOMER): {
      return {
        customer: {
          ...state.customer,
          isLoadingCreate: false,
          created: {}
        }
      };
    }
    case asyncTypeRequest(customerTypes.GET_PIN_CUSTOMERS): {
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingPin: true
        }
      };
    }

    case asyncTypeSuccess(customerTypes.GET_PIN_CUSTOMERS): {
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingPin: false,
          listPin: [...action?.response?.data]
        }
      };
    }
    case asyncTypeFailure(customerTypes.GET_PIN_CUSTOMERS): {
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingPin: false,
          listPin: []
        }
      };
    }
    case customerTypes.RESET_PIN_CUSTOMERS: {
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingPin: false,
          listPin: []
        }
      };
    }
    case customerTypes.UPDATE_PARAMS_SEARCH_CUSTOMERS: {
      const { payload } = action;
      return {
        ...state,
        paramsSearchCustomers: { ...payload }
      };
    }

    case customerTypes.RESET_PARAMS_SEARCH_CUSTOMERS: {
      return {
        ...state,
        paramsSearchCustomers: { ...DEFAULT_PARAMS_SEARCH_CUSTOMER }
      };
    }

    case asyncTypeRequest(customerTypes.GET_CUSTOMER_DETAIL): {
      return {
        ...state,
        customer: {
          ...state.customer,
          customer: {},
          isLoadingCustomer: true
        }
      };
    }
    case asyncTypeSuccess(customerTypes.GET_CUSTOMER_DETAIL): {
      return {
        ...state,
        customer: {
          ...state.customer,
          customer: action?.response?.data,
          isLoadingCustomer: false
        }
      };
    }
    case asyncTypeRequest(customerTypes.UPDATE_CUSTOMER_DETAIL): {
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoadingCustomer: true
        }
      };
    }
    case asyncTypeSuccess(customerTypes.UPDATE_CUSTOMER_DETAIL): {
      return {
        ...state,
        customer: {
          ...state.customer,
          customer: action?.response?.data,
          isLoadingCustomer: false
        }
      };
    }
    default:
      return state;
  }
};

export default customerReducers;
