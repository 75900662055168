import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import ViewListIcon from '@mui/icons-material/ViewList';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

// Manage REQUESTS
// Meta Key
export const DEFAULT_PARAM_METADATA_REQUESTS = {
  metaKey: `manageRequestsPinIds,manageRequestsColumnHidden,manageRequestsSectionHidden,manageRequestsSectionZoom`
};
export const META_SECTION_REQUEST_ZOOM = 'manageRequestsSectionZoom';
export const META_PIN_REQUEST = 'manageRequestsPinIds';
export const META_SECTION_REQUEST = 'manageRequestsSectionHidden';
export const META_COLUMN_REQUEST = 'manageRequestsColumnHidden';
// Section key
export const MANAGE_REQUESTS = 'manageRequests';
export const NEW_REQUEST = 'newRequest';
// export const IMPORT_CUSTOMER = 'importCustomer';
export const PIN_REQUESTS = 'pinRequests';
export const MANAGE_REQUESTS_SECTIONS_VALUE = [
  NEW_REQUEST,
  PIN_REQUESTS,
  MANAGE_REQUESTS
];
export const MANAGE_REQUEST_SECTIONS = [
  {
    label: 'New Request',
    value: 'newRequest',
    order: 1,
    icon: <PlaylistAddIcon />
  },
  // {
  //   label: 'Import Customer',
  //   value: 'importCustomer',
  //   order: 2,
  //   icon: <VisibilityIcon />
  // },
  {
    label: 'Pinned Requests',
    value: 'pinRequests',
    order: 2,
    icon: <PushPinIcon />
  },
  {
    label: 'Requests',
    value: 'manageRequests',
    order: 3,
    icon: <ViewListIcon />
  }
];
// Columns

export const MANAGE_REQUESTS_COLUMNS = [
  {
    id: 'pin',
    label: 'Pin',
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 1
  },
  {
    id: 'id',
    label: 'Request #',
    align: 'center',
    isDefault: true,
    order: 2
  },
  {
    id: 'title',
    label: 'Title',
    isDefault: true,
    maxWidth: '200px',
    order: 3
  },
  // {
  //   id: 'type',
  //   label: 'Type',
  //   isDefault: false,
  //   order: 4
  // },
  {
    id: 'status',
    label: 'Status',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 5
  },
  {
    id: 'caseName',
    label: 'Case Name',
    isDefault: false,
    order: 7
  },
  {
    id: 'resolution',
    label: 'Resolution',
    isDefault: false,
    order: 8
  },
  // {
  //   id: 'priority',
  //   label: 'Priority',
  //   isDefault: false,
  //   order: 9
  // },
  {
    label: 'Submitted Business',
    id: 'submitByBusiness',
    isDefault: true,
    order: 15
  },
  { label: 'Submitted User', id: 'submitByUser', isDefault: false, order: 17 },
  { label: 'Recipient Business', id: 'provider', isDefault: false, order: 18 },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 19
  },
  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 20
  }
];

export const PERSONAL_MANAGE_REQUESTS_COLUMNS = [
  {
    id: 'pin',
    label: 'Pin',
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 1
  },
  {
    id: 'id',
    label: 'Request #',
    align: 'center',
    isDefault: true,
    order: 2
  },
  {
    id: 'title',
    label: 'Title',
    maxWidth: '200px',
    isDefault: true,
    order: 3
  },
  // {
  //   id: 'type',
  //   label: 'Type',
  //   isDefault: false,
  //   order: 4
  // },
  {
    id: 'status',
    label: 'Status',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 5
  },
  {
    label: 'Submitted Business',
    id: 'submitByBusiness',
    isDefault: false,
    order: 15
  },
  { label: 'Submitted User', id: 'submitByUser', isDefault: false, order: 17 },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 19
  },
  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 20
  }
];

export const DEFAULT_PARAMS_SEARCH_REQUEST = {
  s: '',
  title: '',
  caseName: '',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: ''
};

export const STATUS_REQUESTS_CHIP = {
  open: null,
  'on-hold': 'primary',
  'in-progress': 'secondary',
  approved: 'success',
  transferred: 'warning',
  deleted: 'error'
};

export const PRIORITY_REQUESTS_CHIP = {
  low: null,
  medium: 'primary',
  high: 'warning',
  urgent: 'error',
  'not-available': 'info'
};

export const PRIORITY_OPTS = [
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Not Available', value: 'not-available' }
];

export const SHARE_PARTICIPANTS_PERMISSION = {
  VIEW: 'view',
  REPLY: 'reply'
};

export const CONVERSATION_TYPE = {
  PUBLIC: 'public',
  PRIVATE: 'private'
};
