import { BackdropWrapper } from './styled';

const Backdrop = ({ children, ...rest }) => {
  return (
    <BackdropWrapper {...rest}>
      {children}
    </BackdropWrapper>
  );
};

export default Backdrop;
