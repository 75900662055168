import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
const navbarList = [
  // {
  //   icon: HomeIcon,
  //   desc: 'Home',
  //   secondDesc: '',
  //   badge: 0,
  //   subList: [],
  //   subPath: [],
  //   path: '/'
  // },
  {
    icon: FolderIcon,
    desc: 'Cases',
    secondDesc: '',
    badge: 0,
    subList: [],
    path: '/cases',
    subPath: [{
      path: '/create-case'
    }]
  },
  {
    icon: DescriptionIcon,
    desc: 'Requests',
    secondDesc: '',
    badge: 0,
    subList: [],
    subPath: [{
      path: '/create-request'
    }],
    path: '/requests'
  },
  {
    icon: PersonIcon,
    desc: 'Clients/Patients',
    secondDesc: '',
    badge: 0,
    subList: [],
    subPath: [],
    path: '/customers'
  },
  {
    icon: PermContactCalendarIcon,
    desc: 'Business/Contact',
    secondDesc: '',
    badge: 0,
    subList: [
      {
        icon: ApartmentIcon,
        desc: 'Businesses',
        secondDesc: '',
        badge: 0,
        subList: [],
        subPath: [{
          path: /\/businesses\/\d+/
        }],
        path: '/businesses'
      },
      {
        icon: AccountBoxIcon,
        desc: 'Contacts',
        secondDesc: '',
        badge: 0,
        subList: [],
        subPath: [{
          path: /\/members\/\d+/
        }],
        path: '/members'
      }
    ],
    subPath: [{
      path: /\/businesses\/\d+/
    }, {
      path: /\/members\/\d+/
    }],
  },
  {
    icon: PeopleAltIcon,
    desc: 'Shared with me',
    secondDesc: '',
    badge: 0,
    position: 'bottom',
    subList: [
      {
        icon: FolderIcon,
        desc: 'Cases',
        secondDesc: '',
        badge: 0,
        subList: [],
        subPath: [],
        path: '/shared-cases'
      },
      {
        icon: DescriptionIcon,
        desc: 'Requests',
        secondDesc: '',
        badge: 0,
        subList: [],
        subPath: [],
        path: '/shared-requests'
      },
    ],
    subPath: [],
  }
];

const navbarPersonalList = [
  // {
  //   icon: HomeOutlinedIcon,
  //   desc: 'Home',
  //   secondDesc: '',
  //   badge: 0,
  //   subList: [],
  //   subPath: [],
  //   path: '/'
  // },
  {
    icon: PeopleIcon,
    desc: 'Shared with me',
    secondDesc: '',
    badge: 0,
    subList: [],
    subPath: [{
      path: /\/requests\/\d+/
    }],
    path: '/requests'
  }
];



export default process.env.REACT_APP_PORTAL === 'PERSONAL' ? navbarPersonalList : navbarList;
