import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

export const LoadingButtonWrapper = styled(LoadingButton)(({ theme }) => ({
  minWidth: '100px',
  padding: '6px 22px',
  textTransform: 'initial',
  fontWeight: '600',
  borderRadius: theme.spacing(0.75),
  boxShadow: 'none',
  fontSize: 15,
  position: 'relative',
  '&.MuiButton-sizeLarge': {
    height: theme.spacing(6),
    fontSize: 20
  },
  '&.MuiButton-sizeSmall': {
    padding: theme.spacing(0.5, 1)
  },

  '&.cancel-button': {
    backgroundColor: theme.palette.grey[100]
  },
  '&.MuiButton-outlined': {
    backgroundColor: theme.palette.white.main
  },
  '&.fb-button-light': {
    backgroundColor: theme.palette.fbColor.backgroundBtn,
    color: theme.palette.fbColor.black,
    borderRadius: 4,
    padding: '12px 16px',
    height: 38
  },
  '&.fb-button-white': {
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 20,
    height: 36,
    borderRadius: 8,
    position: 'relative',
    color: theme.palette.textColor.black,
    backgroundColor: theme.palette.white.main
  },
  '&.fb-button-insight-input': {
    backgroundColor: 'inherit',
    color: theme.palette.fbColor.main,
    fontWeight: 'normal'
  },
  '&.fb-add': {
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 20,
    height: 36,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.fbColor.main
  },
  '&.fb-button-light-primary': {
    background: theme.palette.fbColor.background,
    color: theme.palette.fbColor.main,
    width: 187
  },
  '&.fb-hover': {
    boxShadow: 'none'
  },
  '&.full-width': {
    width: '100%'
  },
  '&.fb-reset': {
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 20,
    height: 36,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.black.main
  },
  '&.Mui-disabled': {
    opacity: 0.5
  },
  '&.fb-secondary': {
    backgroundColor: `${theme.palette.fbColor.background} !important`,
    color: `${theme.palette.fbColor.main} !important`
  }
}));

export const ButtonWrapper = styled(Button)(({ theme }) => ({
  minWidth: '100px',
  padding: '6px 22px',
  textTransform: 'initial',
  fontWeight: '600',
  borderRadius: theme.spacing(0.75),
  boxShadow: 'none',
  fontSize: 15,
  position: 'relative',
  '&.MuiButton-sizeLarge': {
    height: theme.spacing(6),
    fontSize: 20
  },
  '&.MuiButton-sizeSmall': {
    padding: theme.spacing(0.5, 1)
  },

  '&.cancel-button': {
    backgroundColor: theme.palette.grey[100]
  },
  '&.MuiButton-outlined': {
    backgroundColor: theme.palette.white.main
  },
  '&.fb-button-light': {
    backgroundColor: theme.palette.fbColor.backgroundBtn,
    color: theme.palette.fbColor.black,
    borderRadius: 4,
    padding: '12px 16px',
    height: 38
  },
  '&.fb-button-insight-input': {
    backgroundColor: 'inherit',
    color: theme.palette.fbColor.main,
    fontWeight: 'normal'
  },
  '&.fb-add': {
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 20,
    height: 36,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.fbColor.main
  },
  '&.fb-button-white': {
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 20,
    height: 36,
    borderRadius: 8,
    position: 'relative',
    color: theme.palette.textColor.black,
    backgroundColor: theme.palette.white.main
  },
  '&.fb-button-light-primary': {
    background: theme.palette.fbColor.background,
    color: theme.palette.fbColor.main,
    width: 187
  },
  '&.fb-hover': {
    boxShadow: 'none'
  },
  '&.full-width': {
    width: '100%'
  },
  '&.fb-reset': {
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 20,
    height: 36,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.black.main
  },
  '&.Mui-disabled': {
    opacity: 0.5
  },
  '&.fb-secondary': {
    backgroundColor: `${theme.palette.fbColor.background} !important`,
    color: `${theme.palette.fbColor.main} !important`
  },
  '&.fb-primary': {
    backgroundColor: theme.palette.fbColor.main,
    color: theme.palette.white.main
  }
}));
