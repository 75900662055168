import { createTypes } from 'helpers/createTypes';

export const RESET_CASES = 'RESET_CASES';
export const UPDATE_CASE_THUMBNAIL = 'UPDATE_CASE_THUMBNAIL';

export default createTypes(
  'GET_CASES',
  'GET_CASE_DETAIL',
  'GET_PIN_CASES',
  'RESET_PIN_CASES',
  'UPDATE_CASE_DETAIL',
  'UPDATE_PARAMS_SEARCH_CASES',
  'RESET_PARAMS_SEARCH_CASES',
  'UPDATE_PARAMS_SEARCH_CASES_DETAIL',
  'RESET_PARAMS_SEARCH_CASE_DETAIL',
  'GET_CASE_DETAIL_METADATA',
  'UPDATE_CASE_DETAIL_METADATA',
  'GET_SHARED_CASES',
  'GET_PIN_SHARED_CASES',
  'GET_SHARED_CASE_DETAIL',
  'UPDATE_SHARED_CASE_DETAIL',
);
