import get from 'lodash/get';

export const selectLoadingCustomer = (state) => {
  return get(state, 'customers.customer.isLoadingList', false);
};

export const selectCustomers = (state) => {
  return get(state, 'customers.customer.list', []);
};

export const selectPinCustomers = (state) => {
  return get(state, 'customers.customer.listPin', []);
};

export const selectParamsSearchCustomers = (state) => {
  return get(state, 'customers.paramsSearchCustomers', {});
};

export const selectCreatedCustomer = (state) => {
  return get(state, 'customers.customer.created', {});
};

export const selectCustomer = (state) => {
  return get(state, 'customers.customer.customer', {});
};

export const selectLoadingCustomerDetail = (state) => {
  return get(state, 'customers.customer.isLoadingCustomer', false);
};

export const selectIsSearchAllCustomers = (state) => {
  return get(state, 'customers.customer.isAll', false);
};

