/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

const PublicLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isInitialized, setInit] = useState(false);

  const returnUrl = new URLSearchParams(location?.search).get('returnUrl');

  useEffect(() => {
    if(!!token) {
      if(returnUrl) {
        navigate(returnUrl);
      } else {
        navigate('/');
      }
    } else {
      setInit(true);
    }

  }, []);

  if(!isInitialized) {
    return null;
  }

  if(!!token) return <Navigate to={returnUrl ?? '/'} />;

  return (
    <div style={{ backgroundColor: '#f0f2f5', margin: 0, height: '100%' }}>
      <Outlet />
    </div>
  );
};

PublicLayout.propTypes = {
  Component: PropTypes.any
};

export default PublicLayout;
