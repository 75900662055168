import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import clsx from 'classnames';
import debounce from 'lodash/debounce';

import { formatClientName } from 'helpers/common';
import Popper from '@mui/material/Popper';
import { getCases, getCustomers } from 'portals/business/store/actions';

import { ROUTE_CASE } from 'portals/business/routes';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from 'components/AutoComplete';
import Input from 'components/Input';
import useStyles from './styled';

const LIMITED_CASES = 5;
const LIMITED_CUSTOMERS = 5;

const convertCaseOption = c => ({ ...c, label: c?.title, type: 'case' });
const convertCustomerOption = c => ({ ...c, label: formatClientName(c), type: 'client' });

const PopperMy = (props) => <Popper {...props} sx={{ minWidth: 300 }} placement="bottom-start" />;

const SearchCaseAutocomplete = ({ short = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [cases, setCases] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCasesAsync = async (val = '') => {
    !loading && setLoading(true);
    const res = await dispatch(getCases({ s: val }, {}, false, undefined, false));
    setCases((res?.data || []).slice(0, LIMITED_CASES));
    setLoading(false);
  };

  const getCustomersAsync = async (val = '') => {
    !loading && setLoading(true);
    const res = await dispatch(getCustomers({ s: val }, {}, false, undefined, false));
    setCustomers((res?.data || []).slice(0, LIMITED_CUSTOMERS));
    setLoading(false);
  };

  const onChangeInput = debounce((e) => {
    const { value = '' } = e?.target || {};
    getCasesAsync(value);
    getCustomersAsync(value);
  }, 300);

  const onChange = (evt, item) => {
    setSelected(null);
    switch (item?.type) {
      case 'case':
        navigate(ROUTE_CASE.CASE_DETAIL(item?.id));
        break;
      case 'client':
        navigate(`/customers/${item?.id}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onChangeInput({});
  }, []);

  const options = useMemo(() => [
    ...cases.map(convertCaseOption),
    ...customers.map(convertCustomerOption)
  ], [cases, customers]);

  return (
      <div className={clsx(classes.sidebarSearchCaseClient)}>
        <Autocomplete
          className={clsx(classes.autocomplete, short && 'short')}
          PopperComponent={PopperMy}
          loading={loading}
          defaultValue={selected}
          value={selected}
          autoHighlight
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={(evt, item) => onChange(evt, item, true)}
          options={options}
          maxHeight={400}
          renderInput={(e) => (
            <Input
              {...e}
              className={classes.input}
              onChange={onChangeInput}
              InputProps={{
                ...e?.InputProps,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" sx={{ height: 'auto', margin: 0 }}>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              placeholder="Search case/client"
              size="small"

            />
          )}
          groupBy={(option) => option?.type}
          // renderOption={(option, item) => (
          //   <div className={classes.optionItem} onClick={e => onChange(e, item)}>
          //     {option.key}
          //   </div>
          // )}
          renderGroup={(e) => (
            <li>
              <div className={classes.groupHeader}>
                {e.group === 'case' ? 'Case - DoI' : ''}
                {e.group === 'client' ? 'Client - DoB' : ''}
              </div>
              {e?.children?.length
                ? (
                  <ul className={classes.groupItems}>
                    {e.children}
                  </ul>
                )
                : (
                  <ul className={classes.emptyItem}>
                    No data
                  </ul>
                )}
            </li>
          )}
        />
      </div>
  );
};

export default SearchCaseAutocomplete;
