import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import ViewListIcon from '@mui/icons-material/ViewList';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

// Manage BUSINESSES
// Meta Key
export const DEFAULT_PARAM_METADATA_BUSINESSES = {
  metaKey: `manageContactBusinessPinIds,manageContactBusinessColumnHidden,manageContactBusinessSectionHidden,manageContactBusinessSectionZoom`
};
export const META_PIN_BUSINESS = 'manageContactBusinessPinIds';
export const META_SECTION_BUSINESS = 'manageContactBusinessSectionHidden';
export const META_SECTION_BUSINESS_ZOOM = 'manageContactBusinessSectionZoom';
export const META_COLUMN_BUSINESS = 'manageContactBusinessColumnHidden';
// Section key
export const MANAGE_BUSINESSES = 'manageBusinesses';
export const NEW_BUSINESS = 'newBusiness';
// export const IMPORT_CUSTOMER = 'importCustomer';
export const PIN_BUSINESSES = 'pinBusinesses';
export const MANAGE_BUSINESS_SECTIONS_VALUE = [
  NEW_BUSINESS,
  PIN_BUSINESSES,
  MANAGE_BUSINESSES
];
export const MANAGE_BUSINESS_SECTIONS = [
  {
    label: 'New Business',
    value: 'newBusiness',
    order: 1,
    icon: <PlaylistAddIcon />
  },
  // {
  //   label: 'Import Customer',
  //   value: 'importCustomer',
  //   order: 2,
  //   icon: <VisibilityIcon />
  // },
  {
    label: 'Pinned Businesses',
    value: 'pinBusinesses',
    order: 2,
    icon: <PushPinIcon />
  },
  {
    label: 'Businesses',
    value: 'manageBusinesses',
    order: 3,
    icon: <ViewListIcon />
  }
];
// Columns

export const MANAGE_BUSINESSES_COLUMNS = [
  {
    id: 'pin',
    label: 'Pin',
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 1
  },
  {
    id: 'id',
    label: 'Business #',
    align: 'center',
    isDefault: true,
    order: 2
  },
  {
    id: 'type',
    label: 'Type',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 3
  },
  {
    id: 'name',
    label: 'Business Name',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 4
  },
  { label: 'Phone Number', id: 'phoneNumber', isDefault: false, order: 5 },
  { label: 'Email Address', id: 'emailAddress', isDefault: false, order: 6 },
  {
    id: 'faxNumber',
    label: 'Fax Number',
    isDefault: false,
    order: 7
  },
  {
    id: 'websiteAddress',
    label: 'Website Address',
    isDefault: false,
    order: 8
  },
  {
    id: 'address1',
    label: 'Address',
    isDefault: false,
    order: 9
  },
  {
    id: 'address2',
    label: 'Suite, unit, etc',
    isDefault: false,
    order: 10
  },
  // {
  //   id: 'countryCode',
  //   label: 'Country code',
  //   isDefault: false,
  //   order: 11
  // },
  {
    label: 'City',
    id: 'city',
    isDefault: false,
    order: 12
  },
  {
    id: 'stateCode',
    label: 'State',
    isDefault: false,
    order: 13
  },
  {
    id: 'postCode',
    label: 'Zip/Postal Code',
    isDefault: false,
    order: 14
  },
  // {
  //   id: 'businessGlobalId',
  //   label: 'Business global id',
  //   isDefault: false,
  //   order: 15
  // },
  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 16
  },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 17
  }
];

export const DEFAULT_PARAMS_SEARCH_BUSINESS = {
  s: '',
  name: '',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: ''
};

// Manage Mebers
// Meta Key
export const DEFAULT_PARAM_METADATA_MEMBERS = {
  metaKey: `manageContactMemberPinIds,manageContactMemberColumnHidden,manageContactMemberSectionHidden,manageContactMemberSectionZoom`
};
export const META_PIN_MEMBER = 'manageContactMemberPinIds';
export const META_SECTION_MEMBER = 'manageContactMemberSectionHidden';
export const META_SECTION_MEMBER_ZOOM = 'manageContactMemberSectionZoom';
export const META_COLUMN_MEMBER = 'manageContactMemberColumnHidden';
// Section key
export const MANAGE_MEMBERS = 'manageMembers';
export const NEW_MEMBER = 'newMember';
// export const IMPORT_CUSTOMER = 'importCustomer';
export const PIN_MEMBERS = 'pinMembers';
export const MANAGE_MEMBER_SECTIONS_VALUE = [
  NEW_MEMBER,
  PIN_MEMBERS,
  MANAGE_MEMBERS
];
export const MANAGE_MEMBER_SECTIONS = [
  {
    label: 'New Contact',
    value: 'newMember',
    order: 1,
    icon: <PlaylistAddIcon />
  },
  // {
  //   label: 'Import Customer',
  //   value: 'importCustomer',
  //   order: 2,
  //   icon: <VisibilityIcon />
  // },
  {
    label: 'Pinned Contacts',
    value: 'pinMembers',
    order: 2,
    icon: <PushPinIcon />
  },
  {
    label: 'Contacts',
    value: 'manageMembers',
    order: 3,
    icon: <ViewListIcon />
  }
];
// Columns

export const MANAGE_MEMBER_COLUMNS = [
  {
    id: 'pin',
    label: 'Pin',
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 1
  },
  {
    id: 'id',
    label: 'Contact #',
    align: 'center',
    isDefault: true,
    order: 2
  },
  {
    id: 'firstName',
    label: 'First Name',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 3
  },
  {
    id: 'lastName',
    label: 'Last Name',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 4
  },
  { label: 'Business', id: 'businessName', isDefault: true, order: 5 },
  { label: 'Email Address', id: 'emailAddress', isDefault: true, order: 6 },
  { label: 'Phone Number', id: 'phoneNumber', isDefault: false, order: 7 },
  {
    id: 'address1',
    label: 'Address',
    isDefault: false,
    order: 8
  },
  {
    id: 'address2',
    label: 'Suite, unit, etc',
    isDefault: false,
    order: 9
  },
  {
    label: 'City',
    id: 'city',
    isDefault: false,
    order: 10
  },
  {
    id: 'stateCode',
    label: 'State',
    isDefault: false,
    order: 11
  },
  {
    id: 'postCode',
    label: 'Zip/Postal Code',
    isDefault: false,
    order: 12
  },
  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YYYY'),
    maxWidth: 'fit-content',
    isDefault: false,
    order: 12
  },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YYYY'),
    maxWidth: 'fit-content',
    isDefault: false,
    order: 14
  }
];

export const DEFAULT_PARAMS_SEARCH_MEMBERS = {
  s: '',
  name: '',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: ''
};

export const CATEGORY_MEMBER = 'contact-member';
export const CATEGORY_BUSINESS = 'contact-business';

export const requestTitleDefine = {
  lop: 'Letter of Protection',
  lob: 'Incurred Charges',
  general: 'General Request',
  affidavit: 'Affidavit Request'
};

export const BUSINESS_CODE ={
  ATTORNEY: 'attorney',
  DOCTOR: 'doctor',
  PHARMACY: 'pharmacy',
  MEMBER: 'member'
};
