import { InputWrapper } from './styled';
import PropTypes from 'prop-types';

const Input = (props) => {
  return <InputWrapper {...props} size={props?.size ?? 'small'} />;
};

Input.propTypes = {
  size: PropTypes.string
};

export default Input;
