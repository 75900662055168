import API from 'api';
import userTypes from '../types/user';

export const doLogin = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_LOGIN,
    payload,
    asyncCall: async () => {
      return await API.doLogin(payload);
    },
    afterSuccessCall
  };
};

export const doVerifyLoginOTP = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_VERIFY_LOGIN_OTP,
    payload,
    asyncCall: async () => {
      return await API.doVerifyLoginOTP(payload);
    },
    afterSuccessCall
  };
};

export const doVerifyTwoFactor = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_VERIFY_TWO_FACTOR,
    payload,
    asyncCall: async () => {
      return await API.doVerifyLogin2Factor(payload);
    },
    afterSuccessCall
  };
};

export const sendEmailForgotPassword = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_SEND_FORGOT_PASSWORD,
    payload,
    asyncCall: async () => {
      return await API.sendEmailForgotPassword({
        userType: payload?.userType, emailAddress: payload?.emailAddress
      });
    },
    afterSuccessCall
  };
};

export const doVerifyOTPForgotPassword = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_VERIFY_OTP_FORGOT_PASSWORD,
    payload,
    asyncCall: async () => {
      return await API.doVerifyOTPForgotPassword(payload);
    },
    afterSuccessCall
  };
};

export const resetPassword = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_RESET_PASSWORD,
    payload,
    asyncCall: async () => {
      return await API.resetPassword(payload);
    },
    afterSuccessCall
  };
};

export const updateProfile = (params, afterSuccessCall = () => {}) => {
  const {
    firstName,
    lastName,
    address1,
    address2,
    city,
    stateCode,
    postCode,
    phoneNumber
  } = params;
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.UPDATE_MY_PROFILE,
    payload: {
      firstName,
      lastName,
      address1,
      address2,
      city,
      stateCode,
      postCode,
      phoneNumber
    },
    asyncCall: async () => {
      const res = await API.updateMyProfile(params);
      return res;
    },
    afterSuccessCall
  };
};

export const getMyProfile = () => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.GET_MY_PROFILE,
    payload: {},
    asyncCall: async () => {
      const res = await API.getMyProfile();
      return res;
    }
  };
};

export const updatePassword = (params, afterSuccessCall = () => {}) => {
  const { currentPassword, password } = params;
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.UPDATE_PASSWORD,
    payload: {
      currentPassword,
      password
    },
    asyncCall: async () => {
      const res = await API.updateMyProfile({
        currentPassword,
        newPassword: password
      });
      return res;
    },
    afterSuccessCall
  };
};

export const enableMFA = (params, afterSuccessCall = () => {}) => {
  const { setMfa, mfaSecret, mfaToken } = params;
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.ENABLE_MFA,
    payload: {
      setMfa,
      mfaSecret,
      mfaToken
    },
    asyncCall: async () => {
      const res = await API.updateMyProfile(params);
      return res;
    },
    afterSuccessCall
  };
};

export const resetMyProfile = () => {
  return {
    isAsyncCall: false,
    shouldThrow: true,
    type: userTypes.RESET_ERROR
  };
};

export const resetUser = () => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.RESET_USER,
    asyncCall: async () => {}
  };
};

export const doLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('bId');
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_LOGOUT,
    asyncCall: async () => {}
  };
};
