import isEmpty from 'lodash/isEmpty';
import { asyncTypeFailure, asyncTypeRequest, asyncTypeSuccess } from '../types';
import requestTypes, {
  ADD_EMPTY__CREATE_REQUEST,
  APPLY_ALL__CREATE_REQUEST,
  CLEAR__CREATE_REQUEST,
  DELETE__CREATE_REQUEST,
  SET_COMPLETED_DATA__CREATE_REQUEST,
  UPDATE_PREVIEW_LETTER__CREATE_REQUEST,
  UPDATE_REQUEST__REQUEST_DETAIL
} from '../types/request';

import { DEFAULT_PARAMS_SEARCH_REQUEST } from 'const';

const initialState = {
  error: {
    requests: null,
    statuses: null,
    pinRequests: null
  },
  previewLetter: {
    isLoading: false,
    data: ''
  },
  requests: {
    isLoading: true,
    data: []
  },
  hashtags: {
    isLoading: true,
    data: [],
    created: null,
    isLoadingCreate: false
  },
  pinRequests: {
    isLoading: true,
    data: []
  },
  paramsSearchRequests: DEFAULT_PARAMS_SEARCH_REQUEST,
  statuses: {
    data: [],
    isLoading: true
  },
  request: {
    data: {},
    isLoading: true,
    created: null,
    isLoadingCreate: false
  },
  createRequest: {
    completed: []
  },
  templates: {
    data: [],
    isLoading: true
  },
  shared: {
    isCreate: true,
    data: []
  },
  pinSharedRequests: {
    isLoading: true,
    data: []
  },
  conversations: {}
};

const customerReducers = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_ALL__CREATE_REQUEST: {
      return {
        ...state,
        createRequest: {
          completed: action?.data || []
        }
      };
    }
    case ADD_EMPTY__CREATE_REQUEST: {
      return {
        ...state,
        createRequest: {
          completed: [...state?.createRequest?.completed, { action: action?.data || null }]
        }
      };
    }
    case CLEAR__CREATE_REQUEST: {
      return {
        ...state,
        createRequest: {
          completed: []
        }
      };
    }
    case DELETE__CREATE_REQUEST: {
      return {
        ...state,
        createRequest: {
          completed: state?.createRequest?.completed?.filter(
            (e, idx) => idx !== action?.data
          )
        }
      };
    }
    case SET_COMPLETED_DATA__CREATE_REQUEST: {
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          completed: state?.createRequest?.completed?.length
            ? state.createRequest.completed.map((e, idx) =>
                idx === action.requestIdx
                  ? { ...e, ...(action?.data || {}) }
                  : e
              )
            : [action?.data]
        }
      };
    }
    case asyncTypeRequest(requestTypes.GET_REQUESTS): {
      return {
        ...state,
        requests: {
          ...state.requests,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_REQUESTS): {
      const { isLoadmore } = action?.payload;
      return {
        ...state,
        requests: {
          ...state.requests,
          data: isLoadmore
            ? [...state.requests.data, ...action?.response?.data]
            : action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          requests: null
        }
      };
    }

    case asyncTypeFailure(requestTypes.GET_REQUESTS): {
      return {
        ...state,
        requests: {
          ...state.requests,
          isLoading: false
        },
        error: {
          ...state.error,
          requests: action?.error?.message
        }
      };
    }
    case asyncTypeRequest(requestTypes.GET_PIN_REQUESTS): {
      return {
        ...state,
        pinRequests: {
          ...state.pinRequests,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_PIN_REQUESTS): {
      return {
        ...state,
        pinRequests: {
          ...state.pinRequests,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          requests: null
        }
      };
    }

    case asyncTypeFailure(requestTypes.GET_PIN_REQUESTS): {
      return {
        ...state,
        pinRequests: {
          ...state.pinRequests,
          isLoading: false
        },
        error: {
          ...state.error,
          pinRequests: action?.error?.message
        }
      };
    }

    case requestTypes.RESET_PIN_CASES: {
      return {
        ...state,
        pinRequests: {
          ...state.pinRequests,
          data: []
        }
      };
    }
    case requestTypes.UPDATE_PARAMS_SEARCH_REQUESTS: {
      const { payload } = action;
      return {
        ...state,
        paramsSearchRequests: { ...payload }
      };
    }

    case requestTypes.RESET_PARAMS_SEARCH_REQUESTS: {
      return {
        ...state,
        paramsSearchRequests: { ...DEFAULT_PARAMS_SEARCH_REQUEST }
      };
    }

    case asyncTypeRequest(requestTypes.GET_REQUEST_STATUSES): {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_REQUEST_STATUSES): {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          statuses: null
        }
      };
    }

    case asyncTypeFailure(requestTypes.GET_REQUEST_STATUSES): {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: false
        },
        error: {
          ...state.error,
          statuses: action?.error?.message
        }
      };
    }
    // create request
    case asyncTypeRequest(requestTypes.CREATE_REQUEST): {
      return {
        ...state,
        request: {
          ...state.request,
          isLoadingCreate: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.CREATE_REQUEST): {
      return {
        ...state,
        request: {
          ...state.request,
          created: action?.data || {},
          isLoadingCreate: false
        },
        error: {
          ...state.error,
          createRequest: null
        }
      };
    }

    case asyncTypeFailure(requestTypes.CREATE_REQUEST): {
      return {
        ...state,
        request: {
          ...state.request,
          isLoadingCreate: false
        },
        error: {
          ...state.error,
          createRequest: action?.error?.message
        }
      };
    }

    // get templates
    case asyncTypeRequest(requestTypes.GET_REQUEST_TEMPLATES): {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_REQUEST_TEMPLATES): {
      return {
        ...state,
        templates: {
          ...state.templates,
          data: action?.response?.data || [],
          isLoading: false
        }
      };
    }

    case asyncTypeFailure(requestTypes.GET_REQUEST_TEMPLATES): {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: false
        }
      };
    }
    case asyncTypeRequest(requestTypes.GET_REQUEST_DETAIL): {
      return {
        ...state,
        request: {
          ...state.request,
          data: {},
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_REQUEST_DETAIL): {
      const requestDetail = action?.response?.data || {};
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...requestDetail,
            letters: (requestDetail?.letters || [])
              .map(letter => ({
                ...letter,
                headline: isEmpty(letter?.headline)
                  ? {}
                  : {
                    ...letter?.headline,
                    amount: +(letter?.headline?.content || '').replace('$', '')
                  }
              }))
          },
          isLoading: false
        }
      };
    }
    case asyncTypeRequest(requestTypes.UPDATE_REQUEST_DETAIL): {
      return {
        ...state,
        request: {
          ...state.request,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.UPDATE_REQUEST_DETAIL): {
      return {
        ...state,
        request: {
          ...state.request,
          data: action?.payload?.replace
            ? action?.response?.data
            : state?.request?.data,
          isLoading: false
        }
      };
    }

    // get preview letter
    case asyncTypeRequest(requestTypes.GET_PREVIEW_LETTER): {
      return {
        ...state,
        previewLetter: {
          ...state.previewLetter,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_PREVIEW_LETTER): {
      return {
        ...state,
        previewLetter: {
          ...state.previewLetter,
          data: action?.response?.data?.html || '',
          isLoading: false
        }
      };
    }
    case asyncTypeFailure(requestTypes.GET_PREVIEW_LETTER): {
      return {
        ...state,
        previewLetter: {
          ...state.previewLetter,
          isLoading: false
        }
      };
    }
    case UPDATE_PREVIEW_LETTER__CREATE_REQUEST:
      return {
        ...state,
        previewLetter: {
          ...state.previewLetter,
          data: action?.data
        }
      };
    // get hashtags
    case asyncTypeRequest(requestTypes.GET_REQUEST_HASHTAGS): {
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_REQUEST_HASHTAGS): {
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          data: action?.response?.data || [],
          isLoading: false
        }
      };
    }

    case asyncTypeFailure(requestTypes.GET_REQUEST_HASHTAGS): {
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          isLoading: false
        }
      };
    }
    // create hashtag
    case asyncTypeRequest(requestTypes.CREATE_REQUEST_HASHTAG): {
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          isLoadingCreate: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.CREATE_REQUEST_HASHTAG): {
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          data: [...state.hashtags.data, action?.response?.data],
          created: action?.response?.data || {},
          isLoadingCreate: false
        }
      };
    }

    case asyncTypeFailure(requestTypes.CREATE_REQUEST_HASHTAG): {
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          isLoadingCreate: false
        }
      };
    }

    case UPDATE_REQUEST__REQUEST_DETAIL: {
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request?.data,
            hashtags: action?.data || []
          }
        }
      };
    }

    case asyncTypeSuccess(requestTypes.CREATE_CONVERSATION): {
      const { payload, response } = action;
      const { data } = response;
      const { letterIndex } = payload;
      const letters = state?.request?.data?.letters ?? [];
      const letter = letters[letterIndex];
      if (letter.conversations) {
        letter.conversations.unshift(data);
      } else {
        letter.conversations = [data];
      }
      letters[letterIndex] = letter;
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request?.data,
            letters
          }
        }
      };
    }

    case asyncTypeSuccess(requestTypes.UPDATE_CONVERSATION): {
      const { payload: rootPayload } = action;
      const { letterIndex, conversationIndex, payload } = rootPayload;
      const letters = state?.request?.data?.letters ?? [];
      const letter = letters[letterIndex];

      letter.conversations[conversationIndex].participants = [
        ...payload.participants
      ];
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request?.data,
            letters: [...letters]
          }
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_REQUEST_CONVERSATIONS): {
      return {
        ...state,
        conversations: { ...action?.response?.data }
      };
    }
    case asyncTypeRequest(requestTypes.GET_REQUEST_CONVERSATIONS): {
      return {
        ...state,
        conversations: {}
      };
    }

    // case asyncTypeSuccess(requestTypes.SEND_MESSAGE_CONVERSATION): {
    //   const { payload: rootPayload, response } = action;
    //   const { letterIndex, conversationIndex } = rootPayload;
    //   const letters = state?.request?.data?.letters ?? [];
    //   const letter = letters[letterIndex];

    //   letter.conversations[conversationIndex].messages = [
    //     ...letter.conversations[conversationIndex].messages,
    //     response.data
    //   ];

    //   return {
    //     ...state,
    //     request: {
    //       ...state.request,
    //       data: {
    //         ...state.request?.data,
    //         letters: [...letters]
    //       }
    //     }
    //   };
    // }
    case requestTypes.UPDATE_SHARED: {
      const { payload } = action;
      return {
        ...state,
        shared: { ...payload }
      };
    }
    case requestTypes.RESET_SHARED: {
      return {
        ...state,
        shared: {
          isCreate: true,
          data: []
        }
      };
    }

    case asyncTypeRequest(requestTypes.GET_SHARED_REQUESTS): {
      return {
        ...state,
        sharedRequests: {
          ...state.requests,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_SHARED_REQUESTS): {
      const { isLoadmore } = action?.payload;
      return {
        ...state,
        sharedRequests: {
          ...state.requests,
          data: isLoadmore
            ? [...state.requests.data, ...action?.response?.data]
            : action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          requests: null
        }
      };
    }
    case asyncTypeFailure(requestTypes.GET_SHARED_REQUESTS): {
      return {
        ...state,
        sharedRequests: {
          ...state.requests,
          isLoading: false
        },
        error: {
          ...state.error,
          requests: action?.error?.message
        }
      };
    }

    case asyncTypeRequest(requestTypes.GET_PIN_SHARED_REQUESTS): {
      return {
        ...state,
        pinSharedRequests: {
          ...state.pinSharedRequests,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(requestTypes.GET_PIN_SHARED_REQUESTS): {
      const { isLoadmore } = action?.payload;
      return {
        ...state,
        pinSharedRequests: {
          ...state.pinSharedRequests,
          data: isLoadmore
            ? [...state.pinSharedRequests.data, ...action?.response?.data]
            : action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          pinSharedRequests: null
        }
      };
    }
    case asyncTypeFailure(requestTypes.GET_PIN_SHARED_REQUESTS): {
      return {
        ...state,
        pinSharedRequests: {
          ...state.pinSharedRequests,
          isLoading: false
        },
        error: {
          ...state.error,
          pinSharedRequests: action?.error?.message
        }
      };
    }

    default:
      return state;
  }
};

export default customerReducers;
