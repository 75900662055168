import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import AvatarBusiness from 'components/Avatar/Business';
import { selectMyBusiness } from 'portals/business/store/selectors';
import { getMyBusinessToken } from 'portals/business/store/actions';
import { cbChangeBusiness } from 'helpers/common';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import Button from 'components/Button';

import { ROUTE_BUSINESS } from '../../routes';

import useStyles from './styled';

const BusinessItem = ({ business, onHover, onOver, isInputValue }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.portalBusiness}
    >
      <AvatarBusiness
        business={business}
      />
      <div className="business-info">
        <p className="name">{business?.name}</p>
        {!isInputValue && (
          <p className="type">
            <span>{business?.type?.name}</span>
            {business?.role?.lastName && <span className="role">{`- ${business.role.lastName}`}</span>}
          </p>
        )}
      </div>
    </div>
  );
};

const SelectBusinessDropdown = ({ short }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const myBusiness = useSelector(state => selectMyBusiness(state));
  const businessId = localStorage.getItem('bId');
  const defaultValue = myBusiness?.find(e => e?.id === +businessId);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleGetBusinessToken = async (id, business) => {
    try {
      const res = await dispatch(getMyBusinessToken(
        { data: { type: 'business', id } }
      ));
      localStorage.setItem('token', res?.data?.accessToken);
      localStorage.setItem('bId', id);
      localStorage.setItem('bgColor', business?.type?.color);
      cbChangeBusiness();
    } catch (error) {
      console.log('error', error);
    }
  };


  const handlePopoverOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onChangeBusiness = (business) => {
    handlePopoverClose();
    handleGetBusinessToken(business?.id, business);
  };

  const onBack = () => {
    navigate(ROUTE_BUSINESS.SELECT_BUSINESS);
  };


  return (
    <>
      <div
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
      >
        <AvatarBusiness
          business={defaultValue}
        />
      </div>
      {
        myBusiness?.length > 1 && (
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            className={classes.selectBusinessModal}
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handlePopoverClose}
            MenuListProps={{ onMouseLeave: handlePopoverClose }}
            getContentAnchorEl={null}
          >
            {
              myBusiness?.map(b => (
                <MenuItem onClick={() => onChangeBusiness(b)} className={classes.menuItem} value={b} key={b?.id}>
                  <BusinessItem business={b} />
                </MenuItem>
              ))
            }
            <Button className="fb-button-light fb-hover" variant="contained" type="button" size="small" onClick={onBack} fullWidth sx={{ mt: 2 }}>Choose your other business</Button>
          </Menu>
        )
      }
    </>
  );
};

export default SelectBusinessDropdown;
