import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import ViewListIcon from '@mui/icons-material/ViewList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

// Manage Cases
// Meta key
export const DEFAULT_CASES_META = { select: ['lastActivity'] };
export const META_PIN_CASE = 'manageCasesPinIds';
export const META_SECTION_CASE_ZOOM = 'manageCasesSectionZoom';
export const META_SECTION_CASE = 'manageCasesSectionHidden';
export const META_COLUMN_CASE = 'manageCasesColumnHidden';
export const DEFAULT_PARAM_METADATA_CASES = {
  metaKey: `manageCasesPinIds,manageCasesColumnHidden,manageCasesSectionHidden,manageCasesSectionZoom`
};
// Section key
export const START_NEW_CASE = 'startNewCase';
export const VIEW_SAMPLE_CASE = 'viewSampleCase';
export const PIN_CASES = 'pinCases';
export const MANAGE_CASES = 'manageCases';
export const MANAGE_CASE_SECTIONS_VALUE = [
  START_NEW_CASE,
  VIEW_SAMPLE_CASE,
  PIN_CASES,
  MANAGE_CASES
];
export const MANAGE_CASE_SECTIONS = [
  {
    label: 'Start New Case',
    value: 'startNewCase',
    order: 1,
    icon: <PlaylistAddIcon />
  },

  {
    label: 'View Sample',
    value: 'viewSampleCase',
    order: 2,
    icon: <VisibilityIcon />
  },
  { label: 'Pinned Cases', value: 'pinCases', order: 3, icon: <PushPinIcon /> },
  {
    label: 'Cases',
    value: 'manageCases',
    order: 4,
    icon: <ViewListIcon />
  }
];
//Column
export const MANAGE_CASE_COLUMNS = [
  {
    id: 'pin',
    label: 'Pin',
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 1
  },
  {
    id: 'case',
    label: 'Case #',
    align: 'center',
    isDefault: true,
    order: 2
  },
  {
    id: 'caseName',
    label: 'Case Name',
    isDefault: true,
    order: 3
  },
  { label: 'Alias', id: 'referenceId', isDefault: false, order: 4 },
  {
    id: 'status',
    label: 'Status',
    maxWidth: 'fit-content',
    isDefault: true,
    order: 5
  },
  {
    id: 'clientName',
    label: 'Client Name',
    isDefault: false,
    order: 6
  },
  {
    id: 'categoryName',
    label: 'Category',
    isDefault: true,
    order: 7
  },
  // { label: 'Resolution', id: 'resolution', isDefault: false, order: 8 },
  // {
  //   label: 'Resolution Details',
  //   id: 'resolutionDetails',
  //   isDefault: false,
  //   order: 9
  // },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 10
  },
  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YYYY'),
    align: 'center',
    maxWidth: 'fit-content',
    isDefault: false,
    order: 11
  },
  // { label: 'Created By', id: 'createdBy', isDefault: false, order: 12 }
];
// Search cases
export const DEFAULT_PARAMS_SEARCH_CASE = {
  s: '',
  customerId: '',
  providerId: '',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: '',
  caseId: '',
  status: '',
  caseName: ''
};

export const STATUS_CASES_CHIP = {
  open: null,
  'on-hold': 'primary',
  'in-progress': 'secondary',
  closed: 'success',
  transferred: 'warning'
};

export const STATUS_LIST = [
  { value: 'open', label: 'Open' },
  { value: 'on-hold', label: 'On Hold' },
  { value: 'in-progress', label: 'In Progress' },
  { value: 'closed', label: 'Closed' },
  { value: 'transferred', label: 'Transferred' }
];

export const RESOLUTION_LIST = [
  { value: 'approved', label: 'Approved' },
  { value: 'dropped', label: 'Dropped' },
  { value: 'declined', label: 'Declined' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'transferred', label: 'Transferred' }
];

// Case Detail Meta
export const META_TIME_LINE = 'timelineStatus';
export const META_START_DATE = 'startDate';
export const META_END_DATE = 'endDate';
export const DEFAULT_CASE_META = `${META_TIME_LINE},${META_START_DATE},${META_END_DATE}`;
