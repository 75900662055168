import { createTypes } from 'helpers/createTypes';

export default createTypes(
  'GET_BUSINESSES',
  'GET_PIN_BUSINESSES',
  'UPDATE_PARAMS_SEARCH_BUSINESSES',
  'RESET_PARAMS_SEARCH_BUSINESSES',
  'CREATE_BUSINESS',
  'GET_BUSINESS_TYPES',
  'GET_BUSINESSES_GLOBAL',
  'GET_BUSINESS_DETAIL',
  'UPDATE_BUSINESS_DETAIL',
  'GET_CONTACT_MEMBERS',
  'GET_MEMBERS',
  'GET_PIN_MEMBERS',
  'UPDATE_PARAMS_SEARCH_MEMBERS',
  'RESET_PARAMS_SEARCH_MEMBERS',
  'CREATE_MEMBER',
  'GET_BUSINESS_CATEGORIES',
  'GET_MEMBER_DETAIL',
  'UPDATE_MEMBER_DETAIL',
  'RESET_PIN_BUSINESSES',
  'RESET_PIN_MEMBERS'
);
