import { createTypes } from 'helpers/createTypes';

export default createTypes(
  'USER_LOGIN',
  'UPDATE_MY_PROFILE',
  'GET_MY_PROFILE',
  'UPDATE_PASSWORD',
  'ENABLE_MFA',
  'RESET_ERROR',
  'USER_VERIFY_LOGIN_OTP',
  'RESET_USER',
  'USER_VERIFY_TWO_FACTOR',
  'USER_SEND_FORGOT_PASSWORD',
  'USER_RESET_PASSWORD',
  'USER_LOGOUT',
  'USER_VERIFY_OTP_FORGOT_PASSWORD'
);
