import { appTypes, asyncTypeSuccess, userTypes } from 'store/types';

import { API_RESPONSE_CODE } from 'const';
import SnackbarUtils from 'portals/business/SnackbarUtils';

let countLoading = 0;

const asyncActionCreator =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    const {
      isAsyncCall,
      baseType,
      asyncCall,
      payload = {},
      afterSuccessCall = () => {},
      afterFailureCall = () => {},
      shouldThrow = false,
      showLoading = true
    } = action;

    if (!isAsyncCall) {
      return next(action);
    }

    if (typeof asyncCall !== 'function') {
      throw new Error('Expected asyncCall to be a function.');
    }

    const setLoadingFullscreen = (isShow) => {
      if (showLoading) {
        if (isShow) {
          countLoading++;
          dispatch({ type: appTypes.SET_LOADING_FULLSCREEN, payload: true });
        } else {
          countLoading--;
          // just hide loader when only 1 loader is showing
          if (countLoading === 0 && !!getState()?.app?.isLoadingFullscreen) {
            dispatch({ type: appTypes.SET_LOADING_FULLSCREEN, payload: false });
          }
        }
      }
    };

    try {
      setLoadingFullscreen(true);

      dispatch({
        type: `${baseType}_REQUEST`,
        payload
      });
      const response = await asyncCall(dispatch, getState);
      if (process.env.NODE_ENV === 'development') {
        console.log('===ASYNC CALL RESPONSE===', response);
      }
      setLoadingFullscreen(false);
      if (response?.code === API_RESPONSE_CODE.UNAUTHORIZE) {
        dispatch({
          type: asyncTypeSuccess(userTypes.USER_LOGOUT)
        });
        localStorage.removeItem('token');
        localStorage.removeItem('bId');
        SnackbarUtils.error('Token expired, please login again.');
        window.location.reload();
      } else if (response?.code !== API_RESPONSE_CODE.SUCCESS) {
        dispatch({
          type: `${baseType}_FAILURE`,
          payload,
          error: response
        });
        afterFailureCall(dispatch, response);
      } else {
        dispatch({
          type: `${baseType}_SUCCESS`,
          payload,
          response: response || {}
        });
        afterSuccessCall(dispatch, response);
      }

      return response;
    } catch (error) {
      setLoadingFullscreen(false);

      dispatch({
        type: `${baseType}_FAILURE`,
        payload,
        error
      });
      afterFailureCall(dispatch, error);

      if (shouldThrow) {
        throw error;
      }
    }
  };

export default asyncActionCreator;
