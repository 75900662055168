import { useSnackbar } from 'notistack';

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  success(msg) {
    this.toast(msg, 'success');
  },
  warning(msg) {
    this.toast(msg, 'warning');
  },
  info(msg) {
    this.toast(msg, 'info');
  },
  error(msg) {
    this.toast(msg || 'Oops, something went wrong. Please try again later.', 'error');
  },
  toast(msg, variant = 'default') {
    useSnackbarRef?.enqueueSnackbar && useSnackbarRef.enqueueSnackbar(msg, { variant });
  }
};
