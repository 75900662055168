import { MenuItemWrapper } from './styled';

const MenuItem = ({ children, ...rest }) => {
  return (
    <MenuItemWrapper {...rest}>
      {children}
    </MenuItemWrapper>
  );
};

export default MenuItem;
