import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import clsx from 'classnames';

import Box from '@mui/material/Box';
import { ROUTE_REQUEST } from 'portals/business/routes';
import SideBar from './Sidebar';
import { doLogout } from 'store/actions';
import { matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styled';
import { useTheme } from '@mui/material/styles';
import Loader from 'portals/components/Loader';
import { selectMyProfile } from 'store/selectors';
import { getMyProfile } from 'store/actions';
import { getMyBusiness, getMyBusinessToken } from 'portals/business/store/actions';
import { isPersonalPortal } from 'helpers';

export default function PrivateLayout({ children }) {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const profile = useSelector((state) => selectMyProfile(state));
  const [isFixedSidebar, setFixedSidebar] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const pathsUnneededLayout = ['/select-business', '/active-user'];
  const fullWidthMain = [
    '/cases',
    '/cases/:id/',
    '/customers',
    '/customers/:id/',
    '/businesses',
    '/businesses/:id/',
    '/requests',
    '/requests/:id/',
    ROUTE_REQUEST.CREATE_REQUEST,
    '/shared-requests'
  ];

  const locationParams = new URLSearchParams(location?.search);
  const returnUrl = locationParams.get('returnUrl') || '';

  const handelLogout = () => {
    dispatch(doLogout());
    navigate('/sign-in');
  };

  const handleLoadPrivate = async () => {
    if (
      token &&
      isPersonalPortal() &&
      !['/select-business', '/active-user'].includes(location?.pathname)
    ) {
      /***** PERSONAL *****/
      // const profile = await dispatch(getMyProfile());
      // check user verified
      // if(!profile?.data?.isVerified) {
      //   navigate(`/active-user?returnUrl=${encodeURIComponent(location?.pathname + location?.search)}`);
      // }
      // check user have business - redirect to business site
      const business = await dispatch(getMyBusiness());
      if (business?.data.length && !localStorage.getItem('bId')) {
        const { data } = await dispatch(getMyBusinessToken({
          data: { type: 'business', id: business?.data[0]?.id }
        }));
        if (data && data?.accessToken) {
          const redirectSSO = `${process.env.REACT_APP_BUSINESS_SITE}sso?t=${window.btoa(data?.accessToken)}&bId=${business?.data[0]?.id}&returnUrl=${encodeURIComponent(location?.pathname + location?.search)}`;
          // before redirect to business site, logout on personal site
          dispatch(doLogout());
          window.location.href = redirectSSO;
        }
      } else {
        setLoading(false);
      }
    } else if (
      token &&
      !isPersonalPortal() &&
      !['/select-business', '/active-user'].includes(location?.pathname)
    ) {
      /***** BUSINESS *****/
      // check user have only 1 business - auto select that business
      const business = await dispatch(getMyBusiness());
      if(business?.data.length) {
        if (!localStorage.getItem('bId')) {
          const res = await dispatch(getMyBusinessToken({
            data: { type: 'business', id: business?.data[0]?.id }
          }));
          localStorage.setItem('token', res?.data?.accessToken);
          localStorage.setItem('bId', business?.data[0]?.id);
        }
        setLoading(false);
      } else {
        // if user have not business - redirect to personal
        const redirectSSO = `${process.env.REACT_APP_PERSONAL_SITE}sso?t=${window.btoa(token)}&returnUrl=${encodeURIComponent(location?.pathname + location?.search)}`;
        // before redirect to business site, logout on personal site
        dispatch(doLogout());
        window.location.href = redirectSSO;
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getMyProfile());
    handleLoadPrivate();
  }, []);

  if (!token) {
    // user is not authenticated
    return <Navigate to={`/sign-in?returnUrl=${encodeURIComponent(location?.pathname + location?.search)}`} />;
  }

  if (
    token &&
    profile?.isVerified === false
  ) {
    // user is not verify
    if (returnUrl === '' && location?.pathname.indexOf('active-user') < 0) {
      return <Navigate to={`/active-user?returnUrl=${encodeURIComponent(location?.pathname + location?.search)}`} />;
    }
  }

  if(isLoading) return (
    <Box sx={{
      textAlign: 'center',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Loader />
    </Box>
  );

  if (pathsUnneededLayout.includes(location?.pathname)) {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: '#f0f2f5',
          alignItems: 'center',
          flex: 1,
          height: '100%'
        }}
      >
        <Outlet />
      </Box>
    );
  }

  const isFullWidthMain = !!fullWidthMain.map((path) =>
    matchPath({ path }, location?.pathname)
  );

  const noBackground = ['/'].includes(location?.pathname);

  return (
    <Box sx={{ display: 'flex' }} className={classes.container}>
      <Box
        className={clsx(classes.main, noBackground && classes.noBackground)}
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: isFixedSidebar ? '56px' : '240px',
          width: isFixedSidebar ? 'calc(100% - 56px)' : 'calc(100% - 240px)',
          minHeight: '100vh',
          paddingLeft: isFullWidthMain ? 0 : theme.spacing(2),
          transition: isFixedSidebar
            ? theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen
            })
            : theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            })
        }}
      >
        <Outlet />
      </Box>
      <SideBar setFixedSidebar={setFixedSidebar} onLogout={handelLogout} />
    </Box>
  );
}
