import get from 'lodash/get';

export const selectUser = (state) => {
  return get(state, 'user');
};
export const selectAuthLoading = (state) => {
  return get(state, 'user.isAuthLoading');
};

export const selectAuthError = (state) => {
  return get(state, 'user.error');
};

export const selectIsLoginSuccess = (state) => {
  return get(state, 'user.isLoginSuccess');
};

export const selectReSendPasscodeSuccess = (state) => {
  return get(state, 'user.successState.isReSendPasscodeSuccess');
};
export const selectSendForgotPassSuccess = (state) => {
  return get(state, 'user.successState.isSendForgotPassSuccess');
};

export const selectReSendForgotPassSuccess = (state) => {
  return get(state, 'user.successState.isReSendForgotPassSuccess');
};
export const selectResetPassSuccess = (state) => {
  return get(state, 'user.successState.isResetPassSuccess');
};

export const selectMyProfile = (state) => {
  return get(state, 'user.myProfile.data', {});
};

export const selectAccessToken = (state) => {
  return get(state, 'user.token');
};

export const selectMyProfileError = (state) => {
  return get(state, 'user.error.myProfile', '');
};

export const selectIsUpdateSuccess = (state) => {
  return get(state, 'user.myProfile.isUpdateSuccess', null);
};

export const selectLoadingProfile = (state) => {
  return get(state, 'user.myProfile.isLoading', false);
};

export const selectLoadingUpdateProfile = (state) => {
  return get(state, 'user.myProfile.isLoadingUpdate', false);
};
