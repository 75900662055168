import requestTypes, {
  ADD_EMPTY__CREATE_REQUEST,
  APPLY_ALL__CREATE_REQUEST,
  CLEAR__CREATE_REQUEST,
  DELETE__CREATE_REQUEST,
  SET_COMPLETED_DATA__CREATE_REQUEST,
  UPDATE_PREVIEW_LETTER__CREATE_REQUEST,
  UPDATE_REQUEST__REQUEST_DETAIL
} from '../types/request';

import API from 'api';

export const getRequests = (
  params = {},
  meta = {},
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_REQUESTS,
    payload: { isLoadmore },
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getRequests(params, meta);
      return res;
    }
  };
};

export const getPinRequests = (
  params = {},
  meta = {},
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_PIN_REQUESTS,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getRequests(params, meta);
      return res;
    }
  };
};

export const updateParamsSearchRequests = (payload) => {
  return {
    type: requestTypes.UPDATE_PARAMS_SEARCH_REQUESTS,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const resetParamsSearchRequests = () => {
  return {
    type: requestTypes.RESET_PARAMS_SEARCH_REQUESTS,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const getRequestStatuses = (afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_REQUEST_STATUSES,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getRequestStatuses();
      return res;
    }
  };
};

export const getRequestTypes = (afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_REQUEST_TYPES,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getRequestTypes();
      return res;
    }
  };
};

export const createRequest = (
  payload,
  afterSuccessCall = () => {},
  afterFailureCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.CREATE_REQUEST,
    payload: {},
    afterSuccessCall,
    afterFailureCall,
    asyncCall: async () => {
      const res = await API.createRequest(payload);
      return res;
    }
  };
};

export const getRequestResolutions = (afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_RESOLUTIONS,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getRequestResolutions();
      return res;
    }
  };
};

export const getRequestDetail = (
  id,
  meta,
  afterSuccessCall = () => {},
  afterFailureCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_REQUEST_DETAIL,
    payload: { id },
    asyncCall: async () => {
      const res = await API.getRequestDetail(id, meta);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const updateRequestDetail = (
  pk,
  payload,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.UPDATE_REQUEST_DETAIL,
    payload: { replace: payload?.replace ?? true },
    asyncCall: async () => {
      const res = await API.updateRequestDetail(pk, payload);
      return res;
    },
    afterSuccessCall
  };
};


export const getRequestTemplates = (afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_REQUEST_TEMPLATES,
    payload: {},
    asyncCall: async () => {
      const res = await API.getRequestTemplates();
      return res;
    },
    afterSuccessCall
  };
};

export const addEmptyRequest = (requestType) => ({ type: ADD_EMPTY__CREATE_REQUEST, data: requestType });

export const clearRequest = () => ({ type: CLEAR__CREATE_REQUEST });

export const deleteRequest = (idx) => ({
  type: DELETE__CREATE_REQUEST,
  data: idx
});

export const applyAllRequest = (data) => ({
  type: APPLY_ALL__CREATE_REQUEST,
  data
});

export const setCompletedData = (data, requestIdx = 0) => ({
  type: SET_COMPLETED_DATA__CREATE_REQUEST,
  requestIdx,
  data
});

export const updatePreviewLetter = (data) => ({
  type: UPDATE_PREVIEW_LETTER__CREATE_REQUEST,
  data
});

export const getPreviewLetter = (action, data, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_PREVIEW_LETTER,
    payload: {},
    asyncCall: async () => {
      const res = await API.getPreviewLetter({ action, data });
      return res;
    },
    afterSuccessCall
  };
};

export const replyRequest = (
  requestId,
  payload,
  afterSuccessCall,
  afterFailureCall
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.REPLY_REQUEST,
    payload,
    asyncCall: async () => {
      const res = await API.replyRequest(requestId, payload);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};
export const replySharedRequest = (
  requestId,
  payload,
  afterSuccessCall,
  afterFailureCall
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.REPLY_REQUEST,
    payload,
    asyncCall: async () => {
      const res = await API.replySharedRequest(requestId, payload);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const getRequestHashTags = () => ({
  isAsyncCall: true,
  shouldThrow: true,
  baseType: requestTypes.GET_REQUEST_HASHTAGS,
  asyncCall: async () => {
    const res = await API.getRequestHashTags();
    return res;
  }
});

export const updateRequestHashTags = (
  hashtags = [],
  requestId = '',
  afterSuccessCall = () => {},
  afterFailureCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.UPDATE_REQUEST_HASHTAGS,
    asyncCall: async () => {
      const res = await API.updateRequestHashTags(hashtags, requestId);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const createRequestHashTag = (
  hashtag = '',
  afterSuccessCall = () => {},
  afterFailureCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.CREATE_REQUEST_HASHTAG,
    asyncCall: async () => {
      const res = await API.createRequestHashTag(hashtag);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const updateHashtagByRequest = (hashtags = []) => ({
  type: UPDATE_REQUEST__REQUEST_DETAIL,
  data: hashtags
});

export const createConversation = (
  requestId,
  payload,
  afterSuccessCall,
  portal
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.CREATE_CONVERSATION,
    asyncCall: async () => {
      const res = await API.conversation(requestId, payload, portal);
      return res;
    },
    afterSuccessCall,
    payload: { requestId, payload }
  };
};

export const updateConversation = (
  requestId,
  payload,
  letterIndex,
  conversationIndex,
  afterSuccessCall
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.UPDATE_CONVERSATION,
    asyncCall: async () => {
      const res = await API.updateConversation(requestId, payload);
      return res;
    },
    afterSuccessCall,
    payload: { requestId, payload, letterIndex, conversationIndex }
  };
};

export const sendMessageToConversation = (
  requestId,
  payload,
  afterSuccessCall
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.SEND_MESSAGE_CONVERSATION,
    asyncCall: async () => {
      const res = await API.conversation(requestId, payload);
      return res;
    },
    payload: { requestId, payload },
    afterSuccessCall
  };
};

export const updateShared = (payload) => {
  return {
    type: requestTypes.UPDATE_SHARED,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const resetShared = () => {
  return {
    type: requestTypes.RESET_SHARED,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const responseRequestDeal = (
  requestId,
  payload,
  afterSuccessCall,
  afterFailureCall
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.RESPONSE_REQUEST_DEAL,
    payload,
    asyncCall: async () => {
      const res = await API.responseRequestDeal(requestId, payload);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const responseSharedRequestDeal = (
  requestId,
  payload,
  afterSuccessCall,
  afterFailureCall
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.RESPONSE_REQUEST_DEAL,
    payload,
    asyncCall: async () => {
      const res = await API.responseSharedRequestDeal(requestId, payload);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const getLetterContent = (
  requestId,
  payload,
  afterSuccessCall,
  afterFailureCall
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.RESPONSE_REQUEST_DEAL,
    payload,
    asyncCall: async () => {
      const res = await API.responseRequestDeal(requestId, payload);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const getPdfViewByLetter = (letterId, payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.RESPONSE_REQUEST_DEAL,
    payload,
    asyncCall: async () => {
      const res = await API.getLetterContent(letterId, payload);
      return res;
    }
  };
};
export const getConversations = (payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_REQUEST_CONVERSATIONS,
    payload,
    asyncCall: async () => {
      const res = await API.getConversations(payload);
      return res;
    }
  };
};


export const getSharedRequests = (
  params = {},
  meta = {},
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_SHARED_REQUESTS,
    payload: { isLoadmore },
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getSharedRequests(params, meta);
      return res;
    }
  };
};

export const getPinSharedRequests = (
  params = {},
  meta = {},
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: requestTypes.GET_PIN_SHARED_REQUESTS,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getSharedRequests(params, meta);
      return res;
    }
  };
};
