import Backdrop from 'components/Backdrop';
import CircularProgress from 'components/CircularProgress';

const Loader = () => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default Loader;
