import appTypes from './app';
import businessTypes from './business';
import caseTypes from './case';
import customerTypes from './customer';
import requestTypes from './request';
import userTypes from './user';

export const asyncTypeRequest = (baseAction) => `${baseAction}_REQUEST`;
export const asyncTypeSuccess = (baseAction) => `${baseAction}_SUCCESS`;
export const asyncTypeFailure = (baseAction) => `${baseAction}_FAILURE`;

export {
  appTypes,
  userTypes,
  customerTypes,
  caseTypes,
  businessTypes,
  requestTypes
};
