import { createTypes } from 'helpers/createTypes';

export default createTypes(
  'SET_LOADING_FULLSCREEN',
  'GET_FPTO_MISC',
  'GET_JH_MISC',
  'EMAIL_SUBSCRIPTION',
  'GET_JOB_BY_INDUSTRY',
  'SEARCH_JOB_TITLE',
  'SET_MODAL_ACTIVE',
  'UPDATE_MODAL_DATA'
);
