/* eslint-disable import/no-anonymous-default-export */
import clsx from 'classnames';

import defaultAvatar from 'assets/imgs/user-default.png';

import { getUpperLetters, stringToColor } from './helpers';
import { SIZE, FONT_SIZE, WIDTH } from './consts';

import { AvatarWrapper, AvatarCircle } from './styled';

export { SIZE, FONT_SIZE, WIDTH };

const Avatar = ({
  circle = false,
  circlePadding = 8, // px
  ...props
}) => {
  if (circle) {
    const circleSize = props?.sx?.width + circlePadding;
    return (
      <AvatarCircle sx={{ width: circleSize, height: circleSize, padding: `${circlePadding}px` }}>
        <AvatarWrapper {...props} />
      </AvatarCircle>
    );
  }
  return (
    <AvatarWrapper {...props} />
  );
};

export default ({ user, src, defaultSrc, size = SIZE.S, ...props }) => {
  const aProps = {
    ...props,
    className: clsx('avatar-user', props.className),
    sx: {
      ...(props?.sx || {}),
      fontSize: FONT_SIZE[size],
      width: WIDTH?.[size],
      height: WIDTH?.[size]
    }
  };

  if (user?.avatar) {
    return <Avatar {...aProps} src={user?.avatar} />;
  }
  const fullName = [user?.firstName || '', user?.lastName || ''].join(' ');
  const str = fullName.trim() || user?.emailAddress;
  if (str) {
    return (
      <Avatar {...aProps} sx={{ backgroundColor: stringToColor(str), ...aProps?.sx }}>
        {getUpperLetters(str)}
      </Avatar>
    );
  }
  return <Avatar {...aProps} src={defaultSrc || defaultAvatar} />;
};
