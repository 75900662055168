import API from 'api';
import appTypes from '../types/app';

export const setLoadingFullscreen = (isLoading = false) => {
  return {
    type: appTypes.SET_LOADING_FULLSCREEN,
    payload: isLoading
  };
};

export const setModalActive = (payload) => {
  /*
    payload: {
      modalName: '',
      data: {} // Data in modal
    }
  */
  return {
    type: appTypes.SET_MODAL_ACTIVE,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const setUserMetadata = (payload) => {
  return {
    type: appTypes.SET_USER_METADATA,
    payload
  };
};

export const getSettings = (
  afterSuccessCall = () => { }
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: appTypes.GET_SETTINGS,
    asyncCall: async () => {
      const res = await API.getSettings();
      return res;
    },
    afterSuccessCall
  };
};
