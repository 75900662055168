import get from 'lodash/get';
export const selectCases = (state) => {
  return get(state, 'cases.cases.data', []);
};

export const selectLoadingCases = (state) => {
  return get(state, 'cases.cases.isLoading', false);
};

export const selectCase = (state) => {
  return get(state, 'cases.case.data', {});
};

export const selectCaseMetadata = (state) => {
  return get(state, 'cases.case.metadata', {});
};

export const selectLoadingCase = (state) => {
  return get(state, 'cases.case.isLoading', false);
};

export const selectErrorCase = (state) => {
  return get(state, 'cases.error.case', null);
};

export const selectPinCases = (state) => {
  return get(state, 'cases.pinCases.data', []);
};

export const selectLoadingPinCases = (state) => {
  return get(state, 'cases.pinCases.isLoading', false);
};

export const selectCategories = (state) => {
  return get(state, 'user.category.list', []);
};

export const selectUpdateCaseError = (state) => {
  return get(state, 'cases.error.updateCase', null);
};

export const selectParamsSearchCases = (state) => {
  return get(state, 'cases.paramsSearchCases', {});
};

export const selectParamsSearchCaseDetail = (state) => {
  return get(state, 'cases.paramsSearchCaseDetail', {});
};

export const selectSharedCases = (state) => {
  return get(state, 'cases.sharedCases.data', []);
};

export const selectPinSharedCases = (state) => {
  return get(state, 'cases.pinSharedCases.data', []);
};

export const selectSharedCase = (state) => {
  return get(state, 'cases.sharedCase.data', {});
};

export const selectLoadingSharedCases = (state) => {
  return get(state, 'cases.sharedCases.isLoading', false);
};

export const selectLoadingSharedCase = (state) => {
  return get(state, 'cases.sharedCase.isLoading', false);
};
