import appTypes from '../types/app';

const initialState = {
  isLoadingFullscreen: false,
  modal: {
    modalActive: '',
    data: {}
  }
};

const appRecucers = (state = initialState, action) => {
  switch (action.type) {
    case appTypes.SET_LOADING_FULLSCREEN: {
      return {
        ...state,
        isLoadingFullscreen: action.payload
      };
    }
    case appTypes.SET_MODAL_ACTIVE: {
      const { modalName, data } = action.payload;
      return {
        ...state,
        modal: { modalActive: modalName, data: { ...data } }
      };
    }
    default: {
      return state;
    }
  }
};

export default appRecucers;
