
const stringToColor = (string = '') => {
  let hash = 0, i = undefined, color = '#';

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

const getUpperLetters = (str) => {
  const splitStr = (str || '').trim().split(' ');
  if (splitStr?.length === 2) {
    return splitStr.map(e => e?.[0] || '').join('').toUpperCase();
  }
  return splitStr?.[0].slice(0, 2).toUpperCase();
};

export { stringToColor, getUpperLetters };
