import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const lang = localStorage.getItem('lang');

if(!lang) {
  localStorage.setItem('lang', 'en');
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: lang || 'en',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export const addResources = (resources = {}, spaceName = 'translation') => {
  Object.keys(resources).forEach(rKey => {
    i18n.addResources(rKey, spaceName, resources[rKey]);
  });
};

export default i18n;
