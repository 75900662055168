import get from 'lodash/get';
export const selectRequests = (state) => {
  return get(state, 'requests.requests.data', []);
};

export const selectLoadingRequests = (state) => {
  return get(state, 'requests.requests.isLoading', false);
};

export const selectPinRequests = (state) => {
  return get(state, 'requests.pinRequests.data', []);
};

export const selectLoadingPinRequests = (state) => {
  return get(state, 'requests.pinRequests.isLoading', false);
};

export const selectParamsSearchRequests = (state) => {
  return get(state, 'requests.paramsSearchRequests', {});
};

export const selectRequestStatuses = (state) => {
  return get(state, 'requests.statuses.data', []);
};

export const selectRequest = (state) => {
  return get(state, 'requests.request.data', {});
};

export const selectLoadingRequestDetail = (state) => {
  return get(state, 'requests.request.isLoading', false);
};

export const selectRequestLetters = (state) => {
  return get(state, 'requests.request.data.letters', []);
};

export const selectCompletedData = (state, idx = 0) => {
  return get(state, `requests.createRequest.completed[${idx}]`, {});
};

export const selectAllCompletedData = (state) => {
  return get(state, 'requests.createRequest.completed', []);
};

export const selectLoadingCreateRequest = (state) => {
  return get(state, 'requests.request.isLoadingCreate', false);
};

export const selectRequestTemplates = (state) => {
  return get(state, 'requests.templates.data', []);
};

export const selectPreviewLetter = (state) => {
  return get(state, 'requests.previewLetter.data', '');
};

export const selectLoadingPreviewLetter = (state) => {
  return get(state, 'requests.previewLetter.isLoading', false);
};

export const selectRequestHashtags = (state) => {
  return get(state, 'requests.hashtags.data', []);
};

export const selectLoadingRequestHashtags = (state) => {
  return get(state, 'requests.hashtags.isLoading', false);
};

export const selectShared = (state) => {
  return get(state, 'requests.shared.data', []);
};

export const selectIsCreateConversation = (state) => {
  return get(state, 'requests.shared.isCreate', true);
};

export const selectRequestConversations = (state) => {
  return get(state, 'requests.conversations', []);
};


export const selectSharedRequests = (state) => {
  return get(state, 'requests.sharedRequests.data', []);
};

export const selectLoadingSharedRequests = (state) => {
  return get(state, 'requests.sharedRequests.isLoading', false);
};
