import * as React from 'react';

import { alpha, styled } from '@mui/material/styles';

import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useNavigate } from 'react-router-dom';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import StyledAvatar from './StyledAvatar';
import Typography from 'components/Typography';
import { selectMyProfile } from 'store/selectors';
import { useSelector } from 'react-redux';
import useStyles from './styled';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

export default function CustomizedMenus({
  isSidebarOpen,
  onLogout,
  closeDrawer
}) {
  const classes = useStyles();
  const userInfo = useSelector((state) => selectMyProfile(state));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ width: isSidebarOpen ? '100%' : 'auto' }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropUpOutlinedIcon />}
        sx={{
          backgroundColor: 'transparent',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            marginRight: '18px',
            paddingLeft: '0px',
            alignItems: 'center',
            alignContent: 'center'
          }}
        >
          <StyledAvatar user={userInfo} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontFamily: 'inherit',
              display: 'block',
              whiteSpace: 'nowrap',
              lineHeight: 'inherit',
              textAlign: 'left'
            }}
            style={{ color: '#262626' }}
          >
            {`${userInfo?.firstName ?? ''} ${userInfo?.lastName ?? ''}`}
          </Typography>
        </Box>
      </Button>
      <StyledMenu
        className={classes.popupLogout}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setTimeout(() => {
              navigate('/profile');
            }, 10);
          }}
        >
          <ManageAccountsOutlinedIcon />
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeDrawer();
            handleClose();
            onLogout();
          }}
          component={Link}
          to="#"
          disableRipple
        >
          <ExitToAppIcon />
          Logout
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
